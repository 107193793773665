import React, { useState, useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import playicon from "../../images/svgs/playicon.svg";

const Waveform = ({ records }) => {
  //    console.log(records)
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const waveformRef = useRef(null);
  const audioRef = useRef(null);
  // const audioFile = 'https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3';
  const audioFile = records;

  useEffect(() => {
    let wavesurfer = null;

    const options = {
      container: waveformRef.current,
      waveColor: "#D9D9D9",
      progressColor: "#2D5BFF",
      cursorColor: "transparent",
      responsive: true,
      barWidth: 3,
      barRadius: 2,
      normalize: true,
      backend: "WebAudio",
      height: 30,
      interact: true,
      cursorWidth: 1,
    };

    wavesurfer = WaveSurfer.create(options);
    wavesurfer.load(audioFile);

    wavesurfer.on("ready", () => {
      setDuration(wavesurfer.getDuration());
    });

    wavesurfer.on("play", () => {
      setIsPlaying(true);
    });

    wavesurfer.on("pause", () => {
      setIsPlaying(false);
    });
    wavesurfer.on("audioprocess", () => {
      setCurrentTime(audioRef.current.currentTime);
      const progress = audioRef.current.currentTime / duration;
      wavesurfer.seekTo(progress);
      wavesurfer.setPlayedProgress(progress);
    });

    const updateProgress = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
        const progress = audioRef.current.currentTime / duration;
        wavesurfer.drawer.progress(progress);
      }
      requestAnimationFrame(updateProgress);
    };
    requestAnimationFrame(updateProgress);
    return () => {
      wavesurfer.destroy();
    };
  }, [duration]);

  const handlePlay = () => {
    setIsPlaying(true);
    audioRef.current.play();
    if (waveformRef.current.wavesurfer) {
      waveformRef.current.wavesurfer.play();
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    if (waveformRef.current.wavesurfer) {
      waveformRef.current.wavesurfer.pause();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    const progress = audioRef.current.currentTime / duration;
    if (waveformRef.current.wavesurfer) {
      waveformRef.current.wavesurfer.seekTo(progress);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#B0BABF",
          width: "100%",
          padding: "10px",
          height: "50",
          borderRadius: "28px",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div onClick={isPlaying ? handlePause : handlePlay}>
          {isPlaying ? "Pause" : <img src={playicon} alt="play" />}
        </div>
        <div
          style={{ width: "100%", height: "30", borderRadius: "28px" }}
          ref={waveformRef}
        ></div>
        <div>
          <p
            style={{
              margin: "0px",
              color: "#353533",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {formatTime(currentTime)}
          </p>
        </div>
      </div>
      <audio
        ref={audioRef}
        onPlay={handlePlay}
        onPause={handlePause}
        onTimeUpdate={handleTimeUpdate}
        src={audioFile}
      />
      <div>
        {/* <span>{formatTime(currentTime)}</span> */}
        <input
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onChange={(e) => {
            audioRef.current.currentTime = e.target.value;
            setCurrentTime(e.target.value);
          }}
        />
        <span>{formatTime(duration)}</span>
      </div>
    </div>
  );
};

export default Waveform;
