import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import AllDatas from "./AllData";
import DataSourcers from "./DataSourcers";
import DataTranslators from "./DataTranslator";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import Axios from "axios";
import successRegistration from "../../images/svgs/successRegistration.svg";
import uploadIcon from "../../images/svgs/uploadIcon.svg";
import "../../styles/customfileinput.css";

const Dialogs = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function DialogsTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

DialogsTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Datas = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [openAddLanguage, setOpenAddLanguage] = useState(false);
  const [allLanguage, setAllLanguage] = useState([]);
  const [loadingLanguage, setLoadingLanguage] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingStatusLannguage, setLoadingStatusLannguage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAddLanguageSuccess, setAddLanguageOpenSuccess] = useState(false);
  const [data, setData] = useState({ Language: "" });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const addLanguage = async (data) => {
    try {
      setLoadingStatusLannguage(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/language`,
        {
          name: data.Language,
        },
        config
      );

      if (response.data.status === "success") {
        setLoadingStatusLannguage(false);
        handleClickOpenAddLanguageSuccess();
      }
    } catch (error) {
      console.log("Error adding language:", error);
      alert(`${error.response.data.message}`);
      setLoadingStatusLannguage(false);
    }
  };

  const handleAddLanguageButtonClick = () => {
    if (!data.Language.trim()) {
      alert("Language filed can not be empty");
    } else {
      addLanguage(data);
    }
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenLanguage = () => {
    setOpenAddLanguage(true);
  };
  const handleCloseLanguage = () => {
    setOpenAddLanguage(false);
  };

  const navigateToDash = () => {
    setOpenAddLanguage(false);
    setAddLanguageOpenSuccess(false);
  };

  const handleClickOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleClickOpenAddLanguageSuccess = () => {
    setAddLanguageOpenSuccess(true);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleCloseAddLanguageSuccess = () => {
    setAddLanguageOpenSuccess(false);
  };
  const navigateTo = () => {
    setOpenSuccess(false);
    setOpen(false);

    // getDataSourcers()
  };
  useEffect(() => {
    const getLanguage = async () => {
      setLoadingLanguage(true);
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/language`,
          config
        );

        if (res.data.status === "success") {
          setAllLanguage(res.data.data);
          setLoadingLanguage(false);
        }
      } catch (error) {
        console.log(error);
        alert(`${error.response.data.message}`);
        setLoadingLanguage(false);
      }
    };

    if (token) {
      getLanguage();
    }
  }, [token]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("UPLOADED DATAS", data);
    if (!selectedFile) {
      alert("Please select a file.");
      setLoadingStatus(false);
      return;
    }

    setLoadingStatus(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("language_id", data.language);

    if (data.datatype === "Data Sourcer") {
      Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer-data`,
        formData,
        config
      )
        .then((res) => {
          if (res.data.status === "success") {
            // console.log('DATAT SSSS', res.data);
            setLoadingStatus(false);
            handleClickOpenSuccess();
          }
        })
        .catch((error) => {
          alert(`${error.response.data.message}`);
          setLoadingStatus(false);
        });
    } else if (data.datatype === "Data Translator") {
      Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/translator-data`,
        formData,
        config
      )
        .then((res) => {
          if (res.data.status === "success") {
            setLoadingStatus(false);
            handleClickOpenSuccess();
          }
        })
        .catch((error) => {
          alert(`${error.response.data.message}`);
          console.log("Error", error);
          setLoadingStatus(false);
        });
    } else if (data.datatype === "Data Recorder") {
      Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/recorder-data`,
        formData,
        config
      )
        .then((res) => {
          if (res.data.status === "success") {
            setLoadingStatus(false);
            handleClickOpenSuccess();
          }
        })
        .catch((error) => {
          alert(`${error.response.data.message}`);
          console.log("Error", error);
          setLoadingStatus(false);
        });
    }
  };

  if (loadingLanguage) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <p>Loading datas....</p>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
          backgroundColor: "#F2F8FD",
        }}
      >
        <div>
          <ul style={{ listStyleType: "none", display: "flex" }}>
            <li style={{ margin: "0 10px", cursor: "pointer" }}>
              <p
                style={{
                  color: activeTab === 1 ? "#0D6EFD" : "#829AB1",
                  fontWeight: "600",
                  textDecoration: activeTab === 1 ? "underline" : "none",
                }}
                onClick={() => handleTabClick(1)}
              >
                All Data
              </p>
            </li>
            <li style={{ margin: "0 10px", cursor: "pointer" }}>
              <p
                style={{
                  color: activeTab === 2 ? "#0D6EFD" : "#829AB1",
                  fontWeight: "600",
                  textDecoration: activeTab === 2 ? "underline" : "none",
                }}
                onClick={() => handleTabClick(2)}
              >
                Data Sourcers
              </p>
            </li>
            <li style={{ margin: "0 10px", cursor: "pointer" }}>
              <p
                style={{
                  color: activeTab === 3 ? "#0D6EFD" : "#829AB1",
                  fontWeight: "600",
                  textDecoration: activeTab === 3 ? "underline" : "none",
                }}
                onClick={() => handleTabClick(3)}
              >
                Data Translator
              </p>
            </li>
          </ul>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: "50%",
          }}
        >
          <div
            onClick={handleClickOpen}
            style={{
              backgroundColor: "#0D6EFD",
              width: "31.25px",
              cursor: "pointer",
              height: "31.25px",
              justifyContent: "center",
              display: "flex",
              borderRadius: "50px",
            }}
          >
            <p
              style={{ fontSize: "20px", color: "white", alignItems: "center" }}
            >
              +
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p
              onClick={handleClickOpen}
              style={{ marginLeft: "10px", cursor: "pointer" }}
            >
              Add New Data{" "}
            </p>
            <p
              style={{
                marginLeft: "10px",
                borderLeft: "3px solid grey",
                height: "30px",
              }}
            ></p>
            <p
              style={{
                marginLeft: "10px",
                color: "#0D6EFD",
                cursor: "pointer",
              }}
              onClick={handleClickOpenLanguage}
            >
              {" "}
              Language
            </p>
          </div>
        </div>
        <div></div>
      </div>
      {activeTab === 1 && (
        <div>
          <AllDatas />
        </div>
      )}
      {activeTab === 2 && (
        <div>
          <DataSourcers />
        </div>
      )}
      {activeTab === 3 && (
        <div>
          <DataTranslators />
        </div>
      )}

      <div>
        <Dialogs
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogsTitle id="customized-dialog-title" onClose={handleClose}>
            {/* Modal title */}
          </DialogsTitle>
          <DialogContent>
            <div>
              <h1
                style={{
                  color: "#4B4B4A",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Add Data
              </h1>
            </div>
            <div style={{ height: "30px" }}></div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <p></p>
                  <p>Data Type</p>
                  <select
                    style={{
                      border: `1px solid ${
                        errors.datatype ? "red" : "#D9E2EC"
                      }`,
                      borderRadius: "6px",
                      width: "507px",
                      height: "48px",
                      padding: "8px 12px",
                    }}
                    {...register("datatype", {
                      required: "Data type is required.",
                    })}
                  >
                    <option value="">select data type</option>
                    <option value="Data Translator">Data Translator</option>
                    <option value="Data Sourcer">Data Sourcer</option>
                    <option value="Data Recorder">Data Recorder</option>
                  </select>
                </div>
                {errors.datatype && (
                  <p style={{ color: "red" }}>{errors.datatype.message}</p>
                )}
                <p></p>
                <p>Select Language</p>
                <select
                  style={{
                    border: `1px solid ${errors.language ? "red" : "#D9E2EC"}`,
                    borderRadius: "6px",
                    width: "507px",
                    height: "48px",
                    padding: "8px 12px",
                  }}
                  {...register("language", {
                    required: "Language is required.",
                  })}
                >
                  <option value="">Select Language</option>
                  {allLanguage.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {errors.language && (
                  <p style={{ color: "red" }}>{errors.language.message}</p>
                )}

                <div>
                  <p></p>
                  <div
                    style={{
                      border: `1px dashed ${errors.file ? "red" : "#848383"}`,
                      height: "200px",
                      width: "507px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p></p>
                      <div>
                        <img src={uploadIcon} alt="uploadIcon" />
                      </div>
                      <p></p>
                      <p
                        style={{
                          color: "#848383",
                          fontSize: "20px",
                          fontweight: "600",
                        }}
                      >
                        Drag and Drop here
                      </p>
                      <p
                        tyle={{
                          color: "#848383",
                          fontSize: "20px",
                          fontweight: "600",
                        }}
                      >
                        or
                      </p>
                      <label htmlFor="fileInput" className="custom-file-input">
                        <p
                          style={{
                            color: "#0D6EFD",
                            fontSize: "20px",
                            fontweight: "600",
                          }}
                        >
                          {" "}
                          Browse files
                        </p>
                      </label>
                    </div>

                    <div>
                      <input
                        type="file"
                        accept=".xlsx, .csv, .pdf"
                        name="file"
                        id="fileInput"
                        {...register(
                          "file"
                          //  {
                          //     required: 'File is required.'
                          // }
                        )}
                        onChange={onFileChange}
                        className="hidden-file-input"
                      />
                    </div>
                  </div>

                  {errors.file && (
                    <p style={{ color: "red" }}>{errors.file.message}</p>
                  )}
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              onClick={handleSubmit(onSubmit)}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0B5ED7";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0D6EFD";
              }}
              style={{
                height: "45px",
                width: "507px",
                borderRadius: "10px",
                backgroundColor: "#0D6EFD",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              {loadingStatus ? (
                <p style={{ margin: "0 auto" }}>loading</p>
              ) : (
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                    margin: "0 auto",
                  }}
                >
                  Upload
                </p>
              )}
            </div>
          </DialogActions>
          <div style={{ height: "30px" }}></div>
        </Dialogs>
      </div>

      <div>
        <Dialogs
          onClose={() => {
            handleCloseSuccess();
            navigateTo();
          }}
          aria-labelledby="customized-dialog-title"
          open={openSuccess}
        >
          <DialogsTitle
            id="customized-dialog-title"
            onClose={() => {
              handleCloseSuccess();
              navigateTo();
            }}
          >
            {/* Modal title */}
          </DialogsTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={successRegistration} alt="line" />
            </div>
            <div style={{ height: "50px" }}></div>
            <h1 style={{ textAlign: "center" }}>Data Uploaded Successfully </h1>
            {/* <p style={{ margin: '0px', textAlign: 'center' }}>An email as been sent to the newly created account.</p> */}
          </DialogContent>
          <div style={{ height: "50px" }}></div>
          <DialogActions>
            <div
              onClick={navigateTo}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0B5ED7";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0D6EFD";
              }}
              style={{
                height: "45px",
                width: "507px",
                borderRadius: "10px",
                backgroundColor: "#0D6EFD",
                display: "flex",
                alignItems: "center",
                marginRight: "35px",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  margin: "0 auto",
                }}
              >
                Back to dashboard
              </p>
            </div>
          </DialogActions>
          <div style={{ height: "30px" }}></div>
        </Dialogs>
      </div>

      <div>
        <Dialogs
          onClose={() => {
            handleCloseAddLanguageSuccess();
            navigateToDash();
          }}
          aria-labelledby="customized-dialog-title"
          open={openAddLanguageSuccess}
        >
          <DialogsTitle
            id="customized-dialog-title"
            onClose={() => {
              handleCloseAddLanguageSuccess();
              navigateToDash();
            }}
          >
            {/* Modal title */}
          </DialogsTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={successRegistration} alt="line" />
            </div>
            <div style={{ height: "50px" }}></div>
            <h1 style={{ textAlign: "center" }}>
              Language Added Successfully{" "}
            </h1>
            {/* <p style={{ margin: '0px', textAlign: 'center' }}>An email as been sent to the newly created account.</p> */}
          </DialogContent>
          <div style={{ height: "50px" }}></div>
          <DialogActions>
            <div
              onClick={navigateToDash}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0B5ED7";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0D6EFD";
              }}
              style={{
                height: "45px",
                width: "507px",
                borderRadius: "10px",
                backgroundColor: "#0D6EFD",
                display: "flex",
                alignItems: "center",
                marginRight: "35px",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  margin: "0 auto",
                }}
              >
                Back to dashboard
              </p>
            </div>
          </DialogActions>
          <div style={{ height: "30px" }}></div>
        </Dialogs>
      </div>

      <div>
        <Dialogs
          onClose={handleCloseLanguage}
          aria-labelledby="customized-dialog-title"
          open={openAddLanguage}
        >
          <DialogsTitle
            id="customized-dialog-title"
            onClose={handleCloseLanguage}
          >
            {/* Modal title */}
          </DialogsTitle>
          <DialogContent>
            <div>
              <form>
                <div>
                  <p></p>
                  <div>
                    <p>Language </p>
                    <input
                      style={{
                        border: `1px solid ${
                          errors.Language ? "red" : "#D9E2EC"
                        }`,
                        borderRadius: "6px",
                        width: "507px",
                        height: "48px",
                        padding: "8px 12px",
                      }}
                      type="text"
                      placeholder="Language"
                      name="Language"
                      value={data.Language} // Set the input value to the state data.Language
                      onChange={handleChange} // Call handleChange to update the 'Language' field
                    />
                  </div>
                </div>
                {errors.Language && (
                  <p style={{ color: "red" }}>{errors.Language.message}</p>
                )}
              </form>
            </div>
          </DialogContent>

          <DialogActions>
            <div
              onClick={handleAddLanguageButtonClick}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0B5ED7";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0D6EFD";
              }}
              style={{
                height: "45px",
                width: "507px",
                borderRadius: "10px",
                backgroundColor: "#0D6EFD",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              {loadingStatusLannguage ? (
                <p style={{ margin: "0 auto" }}>loading</p>
              ) : (
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                    margin: "0 auto",
                  }}
                >
                  Add Language
                </p>
              )}
            </div>
          </DialogActions>
          {/* <p style={{color: 'red'}}>{languagerrors.Language.message}</p> */}
          <div style={{ height: "30px" }}></div>
        </Dialogs>
      </div>
    </div>
  );
};

export default Datas;
