import React, { useCallback, useEffect, useState } from "react";
import { Sidebar as Sider, Menu, MenuItem } from "react-pro-sidebar";
import "../styles/Header.css";
import { FiSearch } from "react-icons/fi";
import home from "../images/svgs/Bl.svg";
import profile_icon from "../images/dash/profile_icon.png";
import notification_icon from "../images/svgs/notification_icon.svg";
import setting_icon_white from "../images/svgs/settings_white.svg";
import setting_icon from "../images/svgs/settings.svg";
import chat_icon from "../images/svgs/chat_icon.svg";
import mic_icon from "../images/dash/mic.svg";
import mic_icon_white from "../images/dash/mic-white.svg";
import bigLogo from "../images/svgs/BigLogo.svg";
import datatrans from "../images/svgs/datatrans.svg";
import datatrans_white from "../images/svgs/datatrans_white.svg";
import datasource from "../images/svgs/datasource.svg";
import datasource_white from "../images/svgs/datasource_white.svg";
import admin from "../images/svgs/admin.svg";
import admin_white from "../images/svgs/admin_white.svg";
import dashboard_white from "../images/svgs/dashboard_white.svg";
import data_image from "../images/svgs/data_image.svg";
import data_image_white from "../images/svgs/data_image_white.svg";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";

import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/auth";

import EventBus from "../common/EventBus";

const Layout = () => {
  const { pathname: path } = useLocation();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [activeComponent, setActiveComponent] = useState(path);

  const handleMenuItemClick = (componentName) => {
    setActiveComponent(componentName);
    if (activeComponent !== "/settings") localStorage.removeItem("Value");
  };

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      // setShowModeratorBoard(currentUser.admin.roles.includes("ROLE_MODERATOR"));
      // setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      // setShowModeratorBoard(false);
      // setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div>
        <Sider
          collapsed={collapsed}
          toggled={toggled}
          onBackdropClick={() => setToggled(false)}
          onBreakPoint={setBroken}
          breakPoint="md"
          width="293px"
          backgroundColor="white"
          rootStyles={{
            color: "#B0BABF",
            boxShadow: "-8px 12px 18px 0 #b3bcd0",
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            zIndex: "999",
            transition: "all 0.3s",
          }}
        >
          <div className="menu-iconcover">
            <div className="logotext">
              <p>
                {menuCollapse ? (
                  <img
                    style={{ width: "50px", height: "22px" }}
                    src={bigLogo}
                    alt="log"
                  />
                ) : (
                  <img
                    style={{ width: "200px", height: "22px" }}
                    src={bigLogo}
                    alt="log"
                  />
                )}
              </p>
            </div>

            <Menu
              iconShape="square"
              rootStyles={{
                marginTop: "44px",
              }}
            >
              <MenuItem
                component={<Link to="/dashboard" />}
                active={activeComponent === "/dashboard"}
                icon={
                  <img
                    src={
                      activeComponent === "/dashboard" ? dashboard_white : home
                    }
                    alt="Dashboard"
                  />
                }
                onClick={() => handleMenuItemClick("/dashboard")}
              >
                Dashboard
              </MenuItem>
              <MenuItem
                component={<Link to="/admin" />}
                active={activeComponent === "/admin"}
                icon={
                  <img
                    src={activeComponent === "/admin" ? admin_white : admin}
                    alt="admin"
                  />
                }
                onClick={() => handleMenuItemClick("/admin")}
              >
                Admins
              </MenuItem>
              <MenuItem
                component={<Link to="/datasourcer" />}
                active={activeComponent === "/datasourcer"}
                icon={
                  <img
                    src={
                      activeComponent === "/datasourcer"
                        ? datasource_white
                        : datasource
                    }
                    alt="Data Sources"
                  />
                }
                onClick={() => handleMenuItemClick("/datasourcer")}
              >
                Data Sourcers
              </MenuItem>
              <MenuItem
                component={<Link to="/translators" />}
                active={activeComponent === "/translators"}
                icon={
                  <img
                    src={
                      activeComponent === "/translators"
                        ? datatrans_white
                        : datatrans
                    }
                    alt="/translators"
                  />
                }
                onClick={() => handleMenuItemClick("/translators")}
              >
                Data Translators
              </MenuItem>

              <MenuItem
                component={<Link to="/recorders" />}
                active={activeComponent === "/recorders"}
                icon={
                  <img
                    src={
                      activeComponent === "/recorders"
                        ? mic_icon_white
                        : mic_icon
                    }
                    alt="/recorders"
                  />
                }
                onClick={() => handleMenuItemClick("/recorders")}
              >
                Data Recorders
              </MenuItem>
              <MenuItem
                component={<Link to="/data" />}
                active={activeComponent === "/data"}
                icon={
                  <img
                    src={
                      activeComponent === "/data"
                        ? data_image_white
                        : data_image
                    }
                    alt="Data"
                  />
                }
                onClick={() => handleMenuItemClick("/data")}
              >
                Data
              </MenuItem>
              <MenuItem
                component={<Link to="/settings" />}
                active={activeComponent === "/settings"}
                icon={
                  <img
                    src={
                      activeComponent === "/settings"
                        ? setting_icon_white
                        : setting_icon
                    }
                    alt="/settings"
                  />
                }
                onClick={() => handleMenuItemClick("/settings")}
              >
                Settings
              </MenuItem>
            </Menu>
          </div>
        </Sider>
      </div>
      <div className="all-wrapper">
        <nav className="navbar-wrapper">
          {broken && (
            <button className="sb-button" onClick={() => setToggled(!toggled)}>
              Menu
            </button>
          )}
          <div className="topbar_main">
            <div className="topbar_text">Dashboard</div>

            <div className="btn-row">
              <div className="input-group" style={{ width: "30vw" }}>
                <input
                  type="text"
                  className="form-control bg-omeif-a border-0 search-0"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search_btn"
                />
                <button
                  className="btn border-0 bg-omeif-a"
                  type="button"
                  id="search_btn"
                >
                  <FiSearch />
                </button>
              </div>
            </div>
            <div className="topbar_icons">
              <div className="topbar_icons_img">
                <img src={chat_icon} alt="Chat Icon" />
              </div>
              <div className="topbar_icons_img">
                <img src={notification_icon} alt="Notify Icon" />
              </div>
              <div className="topbar_icons_img">
                <Nav>
                  <NavDropdown
                    title={<img src={profile_icon} alt="Profile Icon" />}
                    drop={false}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/login" onClick={logOut}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </div>
            </div>
          </div>
        </nav>
        <div className="body-wrapper">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
