import React, { useCallback, useEffect, useState } from "react";
import plus from "../../images/svgs/pluss.svg";
import editIcon from "../../images/dash/edit.svg";
import deleteIcon from "../../images/dash/delete.svg";
import { Modal } from "react-bootstrap";
import axios from "axios";
import successIcon from "../../images/dash/success.svg";
import failureIcon from "../../images/svgs/failure.svg";
import fal from "../../images/svgs/fal.svg";
import DateFormatter from "../common/DateFormatter";
import { useSelector } from "react-redux";

const API_URL = "https://apis.omeifdata.smartfocuss.com";

const Admin = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [allAdmins, setAllAdmins] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [editing, setEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setFormData({
      name: "",
      email: "",
      password: "",
      type: "",
      role: "",
    });
    setEditing(false);
  };
  const [message, setMessage] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    type: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/auth/register`,
        formData,
        config
      );

      if (response.data.status === "success") {
        setSaving(false);
        setMessage({
          status: "success",
          message: "Admin created successfully",
        });
        setFormData({
          name: "",
          email: "",
          password: "",
          type: "",
          role: "",
        });
        getAllData();
        setShow(false);
      }
    } catch (error) {
      setSaving(false);
      setMessage({
        status: "error",
        message: `Error creating admin! ${error.message}`,
      });
    }
  };

  const getAllData = useCallback(async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(API_URL + "/api/admin/users", config)
      .then((res) =>
        setAllAdmins(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        )
      )
      .catch((error) => console.error("Error fetching roles:", error));

    axios
      .get(API_URL + "/api/admin/role", config)
      .then((res) => {
        if (res.data.data.length > 0) {
          setAllRoles(
            res.data.data.sort((a, b) =>
              b.created_at.localeCompare(a.created_at)
            )
          );
        }
      })
      .catch((error) => console.error("Error fetching roles:", error));

    setLoadingPage(false);
  }, [token]);

  useEffect(() => {
    if (token) {
      getAllData();
    }
  }, [token, getAllData]);

  const deleteAdmin = async (userId) => {
    setSaving(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/api/admin/users/${userId}`,
        config
      )
      .then((response) => {
        setSaving(false);
        setMessage({
          status: "success",
          message: "Admin deleted successfully",
        });
        getAllData();
      })
      .catch((error) => {
        setMessage(`Error deleting user: ${error.message}`);
      });
  };

  const editAdmin = (userId) => {
    const selectedId = allAdmins.find((admin) => admin.id === userId);

    setFormData({
      name: selectedId.name,
      role: [],
    });
    setSelectedAdmin(selectedId.id);
    setShow(true);
    setEditing(true);
  };

  const handleRolesChange = (e) => {
    const { name, value } = e.target;
    let avalue = parseInt(value, 10);

    setFormData((prevFormData) => ({
      ...prevFormData,
      role: prevFormData.role.includes(avalue)
        ? prevFormData.role.filter((role) => role !== avalue)
        : [...prevFormData.role, avalue],
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    setSaving(true);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/users/${selectedAdmin}`,
        formData,
        config
      )
      .then((response) => {
        setSaving(false);
        setMessage({
          status: "success",
          message: "Admin edited successfully",
        });
        setFormData({
          name: "",
          role: "",
        });
        getAllData();
        setShow(false);
      })
      .catch((error) => {
        setSaving(false);
        setMessage({
          status: "error",
          message: `Error editing admin! ${error.message}`,
        });
      });

    setSaving(false);
  };

  if (loadingPage) {
    return <p className="text-center fw-bold fs-5">Loading...</p>;
  }

  return (
    <>
      <div>
        <div className="add-role" onClick={handleShow}>
          <div>
            <img src={plus} alt="plussign" />
          </div>
          <div>Add New Admin</div>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            alignItems: "flex-start",
          }}
        >
          <table className="table style--one">
            {allAdmins.length === 0 ? (
              <tbody>
                <tr className="font-bold text-center">
                  <th> No registered translator available</th>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr style={{ background: "red" }}>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Date Created</th>
                  <th></th>
                </tr>
                {allAdmins.map((admin, index) => {
                  return (
                    <tr style={{ textAlign: "left" }} key={index}>
                      <td>{admin.name}</td>
                      <td>{admin.email}</td>
                      <td>
                        {admin.roles.length !== 0
                          ? admin.roles.map((role, roleIndex) => {
                              return (
                                <React.Fragment key={roleIndex}>
                                  {role.name}
                                  &nbsp;
                                </React.Fragment>
                              );
                            })
                          : "No role assigned"}
                      </td>
                      <td>Active</td>
                      <td>
                        <DateFormatter date={admin.created_at} />
                      </td>
                      <td>
                        <div className="role-icons">
                          <img
                            src={editIcon}
                            alt="Edit"
                            onClick={() => editAdmin(admin.id)}
                          />
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            onClick={() => deleteAdmin(admin.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* Modal to Add New Admin */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editing ? "Edit" : "Add New"} Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "50px" }}>
          <form onSubmit={editing ? handleEditSubmit : handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="name" className="input-text">
                Name
              </label>
              <input
                type="text"
                className="form-control input-field mt-2"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
            </div>

            {editing ? (
              ""
            ) : (
              <>
                <div className="form-group mb-3">
                  <label htmlFor="email" className="input-text">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control input-field mt-2"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    id="email"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password" className="input-text">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control input-field mt-2"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    id="password"
                    placeholder="*********"
                    required
                  />
                </div>
              </>
            )}
            <div className="form-row">
              {editing ? (
                ""
              ) : (
                <div className="col-6">
                  <div className="form-group mb-3">
                    <label htmlFor="admin_type" className="input-text">
                      Type
                    </label>
                    <input
                      type="text"
                      id="admin_type"
                      className="form-control input-field"
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      placeholder="Admin Type"
                      required
                    />
                  </div>
                </div>
              )}
              <div className={`${editing ? "col-12" : "col-6"}`}>
                <div className="form-group mb-3">
                  <label htmlFor="role" className="input-text">
                    Role
                  </label>
                  <select
                    className={`form-select ${editing ? "" : "input-field"}`}
                    id="role"
                    name="role"
                    value={formData.role}
                    onChange={editing ? handleRolesChange : handleChange}
                    required
                    multiple={editing ? true : false}
                  >
                    <option>Select role</option>
                    {allRoles.map((role, index) => (
                      <React.Fragment key={index}>
                        <option value={role.id}>{role.name}</option>
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <button className="input-submit">{editing ? "Edit" : "Add"}</button>
          </form>
        </Modal.Body>
      </Modal>
      {/* Modal to Add New Admin */}

      {/* Display status messages */}
      {message && (
        <div className="message-overlay">
          <div className="message">
            <div>
              <img
                src={message.status === "success" ? successIcon : failureIcon}
                alt="Success"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="message-text mt-3">{message.message}</div>
            <div>
              <button
                type="button"
                className="btn btn-warning mt-3"
                onClick={() => {
                  setMessage(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Display status messages */}

      {/* Saving */}
      {saving && (
        <div className="message-overlay">
          <div className="message">
            <img src={fal} alt="loading..." />
          </div>
        </div>
      )}
      {/* Saving */}
    </>
  );
};

export default Admin;
