import React, { useEffect } from "react";

const DataTranslators = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F2F8FD";
  }, []);

  return (
    <div>
      <h1>Data translator</h1>
    </div>
  );
};

export default DataTranslators;
