import React, { useEffect, useState, useCallback } from "react";
import plus from "../../images/svgs/pluss.svg";
import editIcon from "../../images/dash/edit.svg";
import deleteIcon from "../../images/dash/delete.svg";
import on from "../../images/dash/on.svg";
import off from "../../images/dash/off.svg";

import { Modal } from "react-bootstrap";
import axios from "axios";
import successIcon from "../../images/dash/success.svg";
import failureIcon from "../../images/svgs/failure.svg";
import fal from "../../images/svgs/fal.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

export const RolePermission = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [allRoles, setAllRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState(null);

  const [clickedIds, setClickedIds] = useState([]);
  const [addData, setAddData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
  });

  const [rolePermissionData, setRolePermissionData] = useState({
    role: "",
    permission: "",
    enabled: "",
  });

  const handleToggle = (permissionId, roleId, rowId) => {
    const findAndUpdateObject = (id) => {
      const newGroupedData = { ...addData };
      for (const groupKey in newGroupedData) {
        for (let i = 0; i < newGroupedData[groupKey].length; i++) {
          if (newGroupedData[groupKey][i].rowId === id) {
            newGroupedData[groupKey][i].enabled =
              !newGroupedData[groupKey][i].enabled;
            break;
          }
        }
      }
      setAddData(newGroupedData);
    };

    findAndUpdateObject(rowId);

    setRolePermissionData({
      role: roleId,
      permission: permissionId,
      enabled: show,
    });
  };

  useEffect(() => {
    const handleToggleSubmit = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/toggle-role-permission`,
          rolePermissionData,
          config
        );

        if (response.data.status === "success") {
          console.log("Updated successfully");

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          console.error(response.message || "Permission update failed!");
        }
      } catch (error) {
        console.error(`Error updating permission! ${error.message}`);
        toast.success(`Error updating permission! ${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (rolePermissionData.permission !== "" && rolePermissionData.role !== "")
      handleToggleSubmit();
  }, [rolePermissionData, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      formData, // ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/role/create`,
        formData,
        config
      );

      if (response.data.status === "success") {
        setSaving(false);
        setMessage({
          status: "success",
          message: "Role created successfully",
        });
        setFormData({
          name: "",
        });
        setShow(false);
        window.location.reload(true);
      } else {
        setMessage({
          status: "error",
          message: response.message || "Role creation failed!",
        });
      }
    } catch (error) {
      setSaving(false);
      setMessage({
        status: "error",
        message: `Error creating role! ${error.message}`,
      });
    }
  };

  const handleRoleClick = (roleName) => {
    const selectedRoles = allRoles.find((role) => role.name === roleName);
    handleRoleDelete(selectedRoles.id);
  };

  const handleRoleDelete = async (id) => {
    setSaving(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      role_id: id,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/role/delete`,
        data,
        config
      );
      if (response.data.status === "success") {
        setSaving(false);
        setMessage({
          status: "success",
          message: response.data.message,
        });
        window.location.reload(true);
      } else {
        setMessage({
          status: "error",
          message: "Error deleting data" || response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
      setMessage({
        status: "error",
        message: "Error deleting data" || error,
      });
    }
  };

  const getAllData = useCallback(async () => {
    setLoadingRoles(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/role`, config)
      .then((res) =>
        setAllRoles(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        )
      )
      .catch((error) => console.error("Error fetching roles:", error));

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/permission`, config)
      .then((res) =>
        setAllPermissions(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        )
      )
      .catch((error) => console.error("Error fetching permissions:", error));

    setLoadingRoles(false);
  }, [token]);

  useEffect(() => {
    if (allRoles && allPermissions) {
      let int = 0;
      allRoles.forEach((role, index) =>
        allPermissions.forEach((permission, i) => {
          const found = role.permissions.some(
            (obj) => obj.name === permission.name
          );
          let enabled = found;
          const isIdInArray = clickedIds.some(
            (clickedId) =>
              clickedId.permissionId === permission.id &&
              clickedId.roleId === role.id
          );
          if (!isIdInArray) {
            setClickedIds((prevClickedIds) => [
              ...prevClickedIds,
              {
                roleId: role.id,
                roleName: role.name,
                permissionId: permission.id,
                permissionName: permission.name,
                rowId: int++,
                enabled: enabled,
              },
            ]);
          }

          const groupedByRole = clickedIds.reduce((groups, person) => {
            const roleGroup = person.roleName;
            if (!groups[roleGroup]) groups[roleGroup] = [];
            groups[roleGroup].push(person);
            return groups;
          }, {});
          setAddData(groupedByRole);
        })
      );
    }
  }, [allRoles, allPermissions, clickedIds]);

  useEffect(() => {
    if (token) {
      getAllData();
    }
  }, [token, getAllData]);

  if (loadingRoles) {
    return <p className="text-center fw-bold fs-5">Loading...</p>;
  }

  return (
    <>
      <div className="add-role cursor-pointer" onClick={handleShow}>
        <div>
          <img src={plus} alt="plussign" />
        </div>
        <div>Add New Role</div>
      </div>

      {addData.length === 0 ? (
        <p className="text-center fw-bold">
          No recently submitted data available
        </p>
      ) : (
        <>
          {Object.entries(addData).map(([roleGroup, people]) => (
            <div className="mb-5" key={roleGroup}>
              <div className="role-group">
                <div className="role-title">{roleGroup}</div>
                <div className="role-icons">
                  <img src={editIcon} alt="Edit" />
                  <img
                    src={deleteIcon}
                    alt="Edit"
                    onClick={() => handleRoleClick(roleGroup)}
                  />
                </div>
              </div>

              {people.map((person) => (
                <div className="span-role" key={person.permissionName}>
                  <div className="notify_text">{person.permissionName}</div>

                  <div className="theja">
                    <img
                      src={person.enabled ? on : off}
                      alt="on"
                      onClick={() =>
                        handleToggle(
                          person.permissionId,
                          person.roleId,
                          person.rowId
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>
      )}

      {/* Modal to Add New Role */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Admin Role</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "50px" }}>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label htmlFor="role" className="input-text">
                    Role Name
                  </label>
                  <input
                    type="text"
                    id="role"
                    className="form-control input-field"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Role name"
                    required
                  />
                </div>
              </div>
            </div>
            <button className="input-submit">Add</button>
          </form>
        </Modal.Body>
      </Modal>
      {/* Modal to Add New Role */}

      {/* Display status messages */}
      {message && (
        <div className="message-overlay">
          <div className="message">
            <div>
              <img
                src={message.status === "success" ? successIcon : failureIcon}
                alt="Success"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="message-text mt-3">{message.message}</div>
            <div>
              <button
                type="button"
                className="btn btn-warning mt-3"
                onClick={() => {
                  setMessage(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Display status messages */}

      {/* Saving */}
      {saving && (
        <div className="message-overlay">
          <div className="message">
            <img src={fal} alt="loading..." />
          </div>
        </div>
      )}
      {/* Saving */}
    </>
  );
};
