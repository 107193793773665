import React, { useState } from 'react';

const YearPicker = ({ selectedYear, onYearSelect }) => {
  const currentYear = new Date().getFullYear();
  const [startYear] = useState(currentYear - 10); // Set the start year as the current year minus 10
  const [endYear] = useState(currentYear + 20); // Set the end year as the current year plus 10

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value);
    onYearSelect(selectedYear);
  };

  const selectStyles = {
    border: 'none', // Remove the border line
    backgroundColor: 'transparent', // Set the background color as transparent
    outline: 'none', // Remove the outline when focused
  };


  return (
    <select value={selectedYear} onChange={handleYearChange}   style={selectStyles}>
      {Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index).map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearPicker;
