import React from "react";
import notification from "../../images/dash/notification.svg";
import notification_white from "../../images/dash/notification-white.svg";
import lock from "../../images/dash/lock.svg";
import lock_white from "../../images/dash/lock-white.svg";
import help from "../../images/dash/help.svg";
import help_white from "../../images/dash/help-white.svg";
import privacy from "../../images/dash/privacy.svg";
import privacy_white from "../../images/dash/privacy-white.svg";
import profile from "../../images/dash/profile.svg";
import profile_white from "../../images/dash/profile-white.svg";
import { Notify } from "./Notify";
import { useState } from "react";
import { PasswordChange } from "./PasswordChange";
import { Help } from "./Help";
import { Privacy } from "./Privacy";
import { RolePermission } from "./RolePermission";
import { useSelector } from "react-redux";

export const Settings = () => {
  React.useEffect(() => {
    if (window.localStorage.getItem("Value"))
      setActiveComponent(window.localStorage.getItem("Value"));
  }, []);

  const [activeComponent, setActiveComponent] = useState("Notify");
  const userSignin = useSelector((state) => state.userSignin);
  console.log(userSignin);

  const handleMenuItemClick = (componentName) => {
    setActiveComponent(componentName);
    window.localStorage.setItem("Value", componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "Notify":
        return <Notify />;
      case "Password":
        return <PasswordChange />;
      case "Help":
        return <Help />;
      case "Privacy":
        return <Privacy />;
      case "Roles":
        return <RolePermission />;
      default:
        return null;
    }
  };

  return (
    <div
      className="container bg-white border border-10"
      style={{ minHeight: "100vh" }}
    >
      <div className="row">
        <div className="col-4">
          <div className="setting-left">
            <div
              className={`setting-navlink ${
                activeComponent === "Notify" ? "setting-active" : ""
              }`}
              onClick={() => handleMenuItemClick("Notify")}
            >
              <img
                src={
                  activeComponent === "Notify"
                    ? notification
                    : notification_white
                }
                alt="Notification"
              />
              <span>Notification</span>
            </div>
            <div
              className={`setting-navlink ${
                activeComponent === "Password" ? "setting-active" : ""
              }`}
              onClick={() => handleMenuItemClick("Password")}
            >
              <img
                src={activeComponent === "Password" ? lock_white : lock}
                alt="Password"
              />
              <span>Password</span>
            </div>
            <div
              className={`setting-navlink ${
                activeComponent === "Roles" ? "setting-active" : ""
              }`}
              onClick={() => handleMenuItemClick("Roles")}
            >
              <img
                src={activeComponent === "Roles" ? profile_white : profile}
                alt="Roles"
              />
              <span>Role & Permission</span>
            </div>
            <div
              className={`setting-navlink ${
                activeComponent === "Help" ? "setting-active" : ""
              }`}
              onClick={() => handleMenuItemClick("Help")}
            >
              <img
                src={activeComponent === "Help" ? help_white : help}
                alt="Help"
              />
              <span>Help and Support</span>
            </div>
            <div
              className={`setting-navlink ${
                activeComponent === "Privacy" ? "setting-active" : ""
              }`}
              onClick={() => handleMenuItemClick("Privacy")}
            >
              <img
                src={activeComponent === "Privacy" ? privacy_white : privacy}
                alt="Privacy"
              />
              <span>Privacy and Security</span>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="setting-right">{renderActiveComponent()}</div>
        </div>
      </div>
    </div>
  );
};
