import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import plus from "../../images/svgs/pluss.svg";
import Axios from "axios";
import Waveform from "./Waveform";
import successRegistration from "../../images/svgs/successRegistration.svg";
import threeicon from "../../images/svgs/threeicon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import iconOverall from "../../images/svgs/line1.svg";
import lineOverall from "../../images/svgs/sourcerLine.svg";
import lineAproved from "../../images/svgs/sourcline.svg";
import lineDeclined from "../../images/svgs/sourlinedecline.svg";
import linePending from "../../images/svgs/sourclinpendding.svg";
import iconAproved from "../../images/svgs/iconAproved.svg";
import iconDeclined from "../../images/svgs/iconDeclined.svg";
import iconPending from "../../images/svgs/iconPending.svg";
import Button from "@mui/material/Button";
import { NoUserUrl } from "../../components/noUserImg";

const Dialogs = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function DialogsTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

DialogsTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Datasourcer = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [open, setOpen] = useState(false);
  const [allLanguage, setAllLanguage] = useState([]);
  const [allDataSourcers, setAllDataSourcers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedRecorededGroupId, setSelectedRecorededGroupId] =
    useState(null);

  const [
    selectedUserRecordedDataAggregate,
    setSelectedUserRecordedDataAggregate,
  ] = useState(null);
  const [accepted, setAccepted] = useState("");
  const [declined, setDeclined] = useState("");
  const [pending, setPending] = useState("");
  const [overAll, setOverAll] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expand, setExpand] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [loadingDataS, setLoadingDataS] = useState(true);
  const [loadingLanguage, setLoadingLanguage] = useState(true);

  const [groupData, setGroupData] = useState(null);
  const [recordedData, setRecordedData] = useState(null);
  const [loadingDataGroup, setLoadingDataGroup] = useState(false);
  const [divTrue, setDivTrue] = useState(false);
  const [approveLoading, setApprovedLoading] = useState(false);
  const [declineLoading, setDelinedLoading] = useState(false);

  const [groupDataRecorded, setGroupDataRecorded] = useState(null);
  const [loadingDataGroupRecorded, setLoadingDataGroupRecorded] =
    useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#F2F8FD";
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleVisibility = () => {
    setShowUserInfo((prevState) => !prevState);
  };

  const handleChanges = (panel) => (event, isExpand) => {
    setExpand(isExpand ? panel : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenSuccess = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const navigateTo = () => {
    setOpenSuccess(false);
    setOpen(false);
    getDataSourcers();
  };

  useEffect(() => {
    const getLanguage = async () => {
      setLoadingLanguage(true);
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/language`,
          config
        );

        if (res.data.status === "success") {
          setAllLanguage(res.data.data);
          setLoadingLanguage(false);
        }
      } catch (error) {
        console.log(error);
        setLoadingLanguage(false);
      }
    };

    if (token) {
      getLanguage();
    }
  }, [token]);

  const handleGroupClick = async (groupId) => {
    setLoadingDataGroup(true);
    setSelectedGroupId(groupId);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/data-group/${groupId}`,
        config
      );

      if (res.data.status === "success") {
        setGroupData(res.data.data);
        setLoadingDataGroup(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataGroup(false);
    }
  };

  const handleGroupRecorededDataClick = async (groupId) => {
    setLoadingDataGroupRecorded(true);
    setSelectedRecorededGroupId(groupId);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/recorded-data/${groupId}`,
        config
      );

      if (res.data.status === "success") {
        setGroupDataRecorded(res.data.data);
        // console.log('RECORDED DATA', res.data.data);
        setRecordedData(res.data.data);
        setLoadingDataGroupRecorded(false);
        setDivTrue(true);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataGroupRecorded(false);
    }
  };

  const getDataSourcers = useCallback(async () => {
    setLoadingDataS(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/users`,
        config
      );

      if (res.data.status === "success") {
        // console.log(res.data.data);
        setAllDataSourcers(res.data.data);
        setAllDataSourcers(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        );
        setLoadingDataS(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataS(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getDataSourcers();
    }
  }, [token, getDataSourcers]);

  const handleUserClick = async (userId) => {
    const userInfo = allDataSourcers.find((user) => user.id === userId);
    setSelectedUserId(userId);
    setSelectedUserInfo(userInfo);
  };

  const recordedCount = async (userId) => {
    const user = allDataSourcers.find((user) => user.id === userId);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      sourcerer_id: userId,
    };
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/recorded-count`,
        data,
        config
      );
      if (response.data.status === "success" && user) {
        setSelectedUserRecordedDataAggregate(response.data.data);
        setPending(response.data.data.pending);
        setDeclined(response.data.data.declined);
        setAccepted(response.data.data.accepted);
        setOverAll(
          response.data.data.accepted +
            response.data.data.declined +
            response.data.data.pending
        );
      }
    } catch (error) {
      console.log(error);
      // Handle the error
    }
  };

  const handleApprove = async (id) => {
    setApprovedLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      data_id: id,
      status: "accepted",
    };
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/recorded-status`,
        data,
        config
      );
      if (response.data.status === "success") {
        await recordedCount();
        setApprovedLoading(false);
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      setApprovedLoading(false);
      // Handle the error
    }
  };

  const handleDecline = async (id) => {
    setDelinedLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      data_id: id,
      status: "declined",
    };
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/recorded-status`,
        data,
        config
      );
      if (response.data.status === "success") {
        await recordedCount();
        setDelinedLoading(false);
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      setDelinedLoading(false);
      // Handle the error
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoadingStatus(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    Axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/register`,
      {
        email: data.email,
        password: data.password,
        first_name: data.firstname,
        last_name: data.lastname,
        phone_number: data.phone,
        language_id: data.language,
      },
      config
    )
      .then((res) => {
        if (res.data.status === "success") {
          // console.log(res.data);
          reset();
          setLoadingStatus(false);
          handleClickOpenSuccess();
        }
      })
      .catch((error) => {
        alert(`${error.response.data.message}`);
        console.log("Error", error);
        setLoadingStatus(false);
      });
  };

  if (loadingLanguage) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <p>Loading datas....</p>
      </div>
    );
  }

  if (loadingDataS) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <p>Loading data....</p>
      </div>
    );
  }

  const handleClick = (event) => {
    console.log("Button clicked!", event);
    // Handle button click here
  };
  const CustomButton = ({ status }) => {
    let buttonColor;
    switch (status) {
      case "declined":
        buttonColor = "primary";
        break;
      case "pending":
        buttonColor = "warning";
        break;
      case "approved":
        buttonColor = "success";
        break;
      default:
        buttonColor = "info";
    }

    const buttonStyle = {
      width: "66px",
      height: "23px",
      backgroundColor: buttonColor,
      fontSize: "10px",
    };

    return (
      <Button
        style={buttonStyle}
        variant="contained"
        color={buttonColor}
        onClick={handleClick}
      >
        {status}
      </Button>
    );
  };

  return (
    <div style={{ backgroundColor: "#F3F9FF", padding: "5px" }}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {selectedUserId && (
          <div
            className="col-3"
            style={{
              flexDirection: "column",
              display: "flex",
              borderRadius: "10px",
              backgroundColor: "white",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                padding: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ justifyContent: "center" }}>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    alignContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <div>
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      src={
                        selectedUserInfo.profile_image
                          ? selectedUserInfo.profile_image
                          : NoUserUrl
                      }
                      alt="profile-images"
                    />
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                      textAlign: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    {selectedUserInfo.first_name} {selectedUserInfo.last_name}
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#829AB1",
                      textAlign: "center",
                    }}
                  >
                    {selectedUserInfo.email}
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#243B53",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#829AB1",
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Phone No:
                    </span>{" "}
                    {selectedUserInfo.phone_number}
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      color: "#0D6EFD",
                      fontSize: "14px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    Edit Profile
                  </p>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Payment
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          color: "#829AB1",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Amount gotten:
                      </p>
                      <p
                        style={{
                          color: "#243B53",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        N0,000
                      </p>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          color: "#829AB1",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Amount withdrawn:
                      </p>
                      <p
                        style={{
                          color: "#243B53",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        N0,000
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography
                    sx={{
                      width: "50%",
                      flexShrink: 0,
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Account info.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedUserInfo.account === null ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        color: "#829AB1",
                        fontSize: "12px",
                      }}
                      onClick={handleToggleVisibility}
                    >
                      User have not filled account details yet.
                    </p>
                  ) : (
                    <div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "#829AB1",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Full Name:
                        </p>
                        <p
                          style={{
                            color: "#243B53",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Joshe hdhdhdhh
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "#829AB1",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Accnt No.:
                        </p>
                        <p
                          style={{
                            color: "#243B53",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          1234567890
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "#829AB1",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Bank:
                        </p>
                        <p
                          style={{
                            color: "#243B53",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Access Bank
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "#829AB1",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Accnt Type:
                        </p>
                        <p
                          style={{
                            color: "#243B53",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Savings
                        </p>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography
                    sx={{
                      width: "70%",
                      flexShrink: 0,
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    List of Groups ID
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div>
                      <div>
                        {selectedUserInfo.data_groups &&
                        selectedUserInfo.data_groups.length === 0 ? (
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "600",
                              color: "#243B53",
                              fontSize: "12px",
                              width: "100%",
                            }}
                          >
                            No Group created yet
                          </p>
                        ) : (
                          <>
                            {selectedUserInfo.data_groups.map(
                              (group, index) => (
                                <div>
                                  <div
                                    key={index}
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "10px",
                                      backgroundColor:
                                        selectedGroupId === group.groupId
                                          ? "#0D6EFD"
                                          : "transparent",
                                      borderRadius: "5px",
                                      width: "100%",
                                      height: "45px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      handleGroupClick(group.groupId)
                                    }
                                  >
                                    <p
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        margin: "0px",
                                        marginLeft: "15px",
                                        width: "100%",
                                      }}
                                    >
                                      Group ID - {group.groupId}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}

        <div
          className="col-9"
          style={{
            flexDirection: "column",
            display: "flex",
            borderRadius: "10px",
            marginLeft: "10px",
            alignItems: "flex-start",
          }}
        >
          {selectedUserId && selectedUserRecordedDataAggregate && (
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                height: "170px",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  backgroundColor: "white",
                  width: "100%",
                  columnGap: "5px",
                }}
              >
                <div
                  className="col-md-3"
                  style={{
                    borderRadius: "10px",
                    height: "150px",
                    backgroundColor: "#0E61DC",
                  }}
                >
                  <img
                    style={{ padding: "15px" }}
                    src={iconOverall}
                    alt="line"
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    <img src={lineOverall} alt="line" />
                    <div style={{ marginLeft: "15px" }}>
                      <p
                        style={{
                          margin: "0px",
                          color: "#CEDAFE",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        Overall
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          color: "#FFFFFF",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        {overAll}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-3"
                  style={{
                    borderRadius: "10px",
                    height: "150px",
                    backgroundColor: "#2B7F2F",
                  }}
                >
                  <img
                    style={{ padding: "15px" }}
                    src={iconAproved}
                    alt="line"
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    <img src={lineAproved} alt="line" />
                    <div style={{ marginLeft: "15px" }}>
                      <p
                        style={{
                          margin: "0px",
                          color: "#ABFEC1",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        Approved
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          color: "#FFFFFF",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        {accepted}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-3"
                  style={{
                    borderRadius: "10px",
                    height: "150px",
                    backgroundColor: "#DD0A0A",
                  }}
                >
                  <img
                    style={{ padding: "15px" }}
                    src={iconDeclined}
                    alt="line"
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    <img src={lineDeclined} alt="line" />
                    <div style={{ marginLeft: "15px" }}>
                      <p
                        style={{
                          margin: "0px",
                          color: "#FFBDBD",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        Declined
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          color: "#FFFFFF",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        {declined}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-3"
                  style={{
                    borderRadius: "10px",
                    height: "150px",
                    backgroundColor: "#FD9B16",
                  }}
                >
                  <img
                    style={{ padding: "15px" }}
                    src={iconPending}
                    alt="line"
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    <img src={linePending} alt="line" />
                    <div style={{ marginLeft: "15px" }}>
                      <p
                        style={{
                          margin: "0px",
                          color: "#FFEAC6",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        Pending
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          color: "#FFFFFF",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        {pending}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ height: "10px" }}></div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-start",
              columnGap: "5px",
              width: "100%",
            }}
          >
            <div className="col-6">
              {groupData && (
                <div
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    alignItems: "flex-start",
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ height: "16px" }}></div>
                  <p
                    style={{
                      color: "#100F0F",
                      fontSize: "16px",
                      fontWeight: "600",
                      margin: "0px",
                      marginLeft: "15px",
                    }}
                  >
                    {" "}
                    Group ID - {groupData.groupId}
                  </p>
                  <div style={{ height: "12px" }}></div>
                  <div className="px-4">
                    {groupData.group_items.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleGroupRecorededDataClick(item.id)}
                        style={{
                          backgroundColor:
                            selectedRecorededGroupId === item.id
                              ? "#0D6EFD"
                              : "transparent",
                          cursor: "pointer",
                          width: "100%",
                          height: "70px",
                          borderRadius: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <p
                          style={{
                            color: "#100F0F",
                            fontWeight: "400",
                            fontSize: "16px",
                            margin: "0px",
                            marginLeft: "8%",
                          }}
                        >
                          Question ID {item.id}
                        </p>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-around",
                            marginLeft: "6%",
                            lineHeight: "-10px",
                          }}
                        >
                          <p
                            style={{
                              color: "#334E68",
                              fontWeight: "400",
                              fontSize: "12px",
                              margin: "0px",
                            }}
                          >
                            Uploaded 4
                          </p>
                          <p
                            style={{
                              color: "#334E68",
                              fontWeight: "400",
                              fontSize: "12px",
                              margin: "0px",
                            }}
                          >
                            Approved 3
                          </p>
                          <p
                            style={{
                              color: "#334E68",
                              fontWeight: "400",
                              fontSize: "12px",
                              margin: "0px",
                            }}
                          >
                            Approved 3
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {divTrue && groupData && recordedData && (
              <div style={{ alignItems: "flex-start" }} className="col-6">
                <p
                  style={{
                    color: "#100F0F",
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: "0px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Group ID - {groupData.groupId}
                </p>
                <div style={{ height: "10px" }}></div>
                <div style={{ alignItems: "flex-start" }}>
                  <div style={{ alignItems: "flex-start" }}>
                    {groupData.group_items.map((item, index) => (
                      <div style={{ alignItems: "flex-start" }}>
                        <div style={{ margin: "5px", padding: "5px" }}>
                          <Accordion
                            expanded={expand === index}
                            onChange={handleChanges(index)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0px",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Question {item.id}- ID - {groupData.groupId}
                                </p>{" "}
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    width: "66px",
                                    height: "23px",
                                    backgroundColor: "#FFEAC6",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      color: "#FD9B16",
                                      fontSize: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Pending
                                  </p>
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "#353533",
                                }}
                              >
                                {item.sourcerer_data.text}
                              </p>

                              {recordedData.map((record, recordIndex) => (
                                <div>
                                  <Waveform
                                    key={recordIndex}
                                    records={record.recording}
                                  />

                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                    }}
                                  >
                                    {approveLoading ? (
                                      <p style={{ margin: "0" }}>
                                        Please wait approving
                                      </p>
                                    ) : (
                                      <div
                                        onClick={() => handleApprove(record.id)}
                                        style={{
                                          width: "87px",
                                          height: "31px",
                                          alignItems: "center",
                                          borderRadius: "3px",
                                          display: "flex",
                                          justifyContent: "center",
                                          backgroundColor: "#0D6EFD",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "#FFFFFF",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            marginTop: "15px",
                                          }}
                                        >
                                          Approved
                                        </p>
                                      </div>
                                    )}
                                    <div style={{ width: "20px" }}></div>
                                    {declineLoading ? (
                                      <p style={{ margin: "0" }}>
                                        Please wait declining
                                      </p>
                                    ) : (
                                      <div
                                        onClick={() => handleDecline(record.id)}
                                        style={{
                                          width: "87px",
                                          height: "31px",
                                          borderRadius: "3px",
                                          alignContent: "center",
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          backgroundColor: "grey",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "#B0BABF",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            marginTop: "15px",
                                          }}
                                        >
                                          Declined
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "flex-start",
          columnGap: "16px",

          visibility: selectedUserId ? "hidden" : "visible",
        }}
      >
        <div className="card col-6 border-null">
          <div
            style={{
              flexDirection: "row",
              margin: "0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
              height: "60px",
              backgroundColor: "white",
              borderRadius: "10px",
              visibility: selectedUserId ? "hidden" : "visible",
            }}
          >
            <div>
              <h4
                style={{
                  fontWeight: "600",
                  fontSize: "14.6px",
                  color: "#100F0F",
                  paddingLeft: "10px",
                }}
              >
                Data sourcers
              </h4>
            </div>
            <div
              onClick={handleClickOpen}
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <div>
                <img src={plus} alt="plussign" />
              </div>
              <div>
                <h4
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#4B4B4A",
                    marginBottom: "2px",
                    marginLeft: "10px",
                  }}
                >
                  New Data sourcer
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body p-0 bg-none border-null">
            <div className="table-overflow">
              <table className="table style--one">
                {allDataSourcers.length === 0 ? (
                  <p style={{ textAlign: "center", fontWeight: "600" }}>
                    No registered sourcers available
                  </p>
                ) : (
                  <tbody>
                    {allDataSourcers.map((datasources, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            handleUserClick(datasources.id);
                            recordedCount(datasources.id);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <td data-label="User">
                            <div className="user">
                              <div className="thumb">
                                <img
                                  src={
                                    datasources.profile_image
                                      ? datasources.profile_image
                                      : NoUserUrl
                                  }
                                  alt="profile"
                                />
                              </div>
                              <span className="name">
                                {datasources.first_name} {datasources.last_name}
                              </span>
                            </div>
                          </td>
                          <td data-label="Username">{datasources.email}</td>
                          <td data-label="Action">
                            <button
                              onClick={() => {
                                handleUserClick(datasources.id);
                                recordedCount(datasources.id);
                              }}
                              className="icon-button"
                            >
                              <img src={threeicon} alt="iconns" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        <div
          className="col-6"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              margin: "25px",
              visibility: selectedUserId ? "hidden" : "visible",
            }}
          >
            <p
              style={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Source Sans 3",
              }}
            >
              Recently Submitted Data{" "}
            </p>
          </div>

          {allDataSourcers.length === 0 ? (
            <p style={{ textAlign: "center", fontWeight: "600" }}>
              No recently submitted data available
            </p>
          ) : (
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "#F3F9FF",
                  alignItems: "center",
                  height: "50px",
                }}
              >
                <p
                  style={{
                    color: "#243B53",
                    fontSize: "10px",
                    fontWeight: "400",
                    margin: "0px",
                  }}
                >
                  Name
                </p>
                <p
                  style={{
                    color: "#243B53",
                    fontSize: "10px",
                    fontWeight: "400",
                    margin: "0px",
                  }}
                >
                  Type
                </p>
                <p
                  style={{
                    color: "#243B53",
                    fontSize: "10px",
                    fontWeight: "400",
                    margin: "0px",
                  }}
                >
                  Date
                </p>
                <p
                  style={{
                    color: "#243B53",
                    fontSize: "10px",
                    fontWeight: "400",
                    margin: "0px",
                  }}
                >
                  Status
                </p>
              </div>

              <div style={{ overflow: "auto", maxHeight: "600px" }}>
                {allDataSourcers.map((datasource, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "20px",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <img
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                              }}
                              src={
                                datasource.profile_image
                                  ? datasource.profile_image
                                  : NoUserUrl
                              }
                              alt="profile-images"
                            />
                          </div>
                          <div style={{ width: "10px" }}></div>
                          <div>
                            <p
                              style={{
                                margin: "0px",
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {datasource.first_name} {datasource.last_name}
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                color: "#829AB1",
                                fontSize: "10px",
                                fontWeight: "400",
                              }}
                            >
                              Data datasource
                            </p>
                          </div>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              color: "#829AB1",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            Grp ID - 123451VDR
                          </p>
                          <p
                            style={{
                              margin: "0px",
                              color: "#829AB1",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Q01- ID - 123452...
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              color: "#829AB1",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            Jul 29, 2023
                          </p>
                          <p
                            style={{
                              margin: "0px",
                              color: "#829AB1",
                              fontWeight: "400",
                              fontSize: "10px",
                            }}
                          >
                            10:23am
                          </p>
                        </div>
                        <div>
                          <CustomButton status="pending" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <Dialogs
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogsTitle id="customized-dialog-title" onClose={handleClose}>
            {/* Modal title */}
          </DialogsTitle>
          <DialogContent>
            <div>
              <h1 style={{ textAlign: "center" }}>Add New Data Sourcer</h1>
              <p style={{ margin: "0px", textAlign: "center" }}>
                Please provide the information for the new
              </p>
              <p style={{ margin: "0px", textAlign: "center" }}>data sourcer</p>
            </div>
            <div style={{ height: "30px" }}></div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p>First Name</p>
                    <input
                      style={{
                        border: `1px solid ${
                          errors.firstname ? "red" : "#D9E2EC"
                        }`,
                        borderRadius: "6px",
                        width: "241px",
                        height: "48px",
                        padding: "8px 12px",
                      }}
                      type="text"
                      placeholder="First Name"
                      name="firstname"
                      {...register("firstname", {
                        required: "First Name is required.",
                        minLength: {
                          value: 2,
                          message:
                            "First name should be at-least 2 characters.",
                        },
                      })}
                    />
                  </div>
                  <div>
                    <p>Last Name</p>
                    <input
                      style={{
                        border: `1px solid ${
                          errors.lastname ? "red" : "#D9E2EC"
                        }`,
                        borderRadius: "6px",
                        width: "241px",
                        height: "48px",
                        padding: "8px 12px",
                      }}
                      placeholder="Last Name"
                      type="text"
                      name="lastname"
                      {...register("lastname", {
                        required: "Last name is required.",
                        minLength: {
                          value: 2,
                          message: "Last name should be at-least 2 characters.",
                        },
                      })}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {errors.firstname && (
                      <p style={{ color: "red" }}>{errors.firstname.message}</p>
                    )}
                  </div>
                  <div style={{ marginRight: "80px" }}>
                    {errors.lastname && (
                      <p style={{ color: "red" }}>{errors.lastname.message}</p>
                    )}
                  </div>
                </div>
                <div>
                  <p></p>
                  <div>
                    <p>Email Address</p>
                    <input
                      style={{
                        border: `1px solid ${errors.email ? "red" : "#D9E2EC"}`,
                        borderRadius: "6px",
                        width: "507px",
                        height: "48px",
                        padding: "8px 12px",
                      }}
                      type="text"
                      placeholder="Email"
                      name="email"
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          message: "Email is not valid.",
                        },
                      })}
                    />
                  </div>
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email.message}</p>
                  )}
                  <p></p>
                  <div>
                    <p>Phone No.</p>
                    <input
                      style={{
                        border: `1px solid ${errors.phone ? "red" : "#D9E2EC"}`,
                        borderRadius: "6px",
                        width: "507px",
                        height: "48px",
                        padding: "8px 12px",
                      }}
                      type="text"
                      placeholder="Enter Phone No."
                      name="phone"
                      {...register("phone", {
                        required: "Phone number is required.",
                      })}
                    />
                  </div>
                  {errors.phone && (
                    <p style={{ color: "red" }}>{errors.phone.message}</p>
                  )}
                  <p></p>
                  <div>
                    <p>Password</p>
                    <input
                      style={{
                        border: `1px solid ${
                          errors.password ? "red" : "#D9E2EC"
                        }`,
                        borderRadius: "6px",
                        width: "507px",
                        height: "48px",
                        padding: "8px 12px",
                      }}
                      placeholder="Password"
                      type="password"
                      name="password"
                      {...register("password", {
                        required: "Password is required.",
                        minLength: {
                          value: 6,
                          message: "Password should be at-least 6 characters.",
                        },
                      })}
                    />
                  </div>
                </div>
                {errors.password && (
                  <p style={{ color: "red" }}>{errors.password.message}</p>
                )}
                <p></p>
                <p>Select Language</p>
                <select
                  style={{
                    border: `1px solid ${errors.language ? "red" : "#D9E2EC"}`,
                    borderRadius: "6px",
                    width: "507px",
                    height: "48px",
                    padding: "8px 12px",
                  }}
                  {...register("language", {
                    required: "Language is required.",
                  })}
                >
                  <option value="">Select Language</option>
                  {allLanguage.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {errors.language && (
                  <p style={{ color: "red" }}>{errors.language.message}</p>
                )}
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              onClick={handleSubmit(onSubmit)}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0B5ED7";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0D6EFD";
              }}
              style={{
                height: "45px",
                width: "507px",
                borderRadius: "10px",
                backgroundColor: "#0D6EFD",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              {loadingStatus ? (
                <p style={{ margin: "0 auto" }}>loading</p>
              ) : (
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                    margin: "0 auto",
                  }}
                >
                  Register
                </p>
              )}
            </div>
          </DialogActions>
          <div style={{ height: "30px" }}></div>
        </Dialogs>
      </div>

      <div>
        <Dialogs
          onClose={() => {
            handleCloseSuccess();
            navigateTo();
          }}
          aria-labelledby="customized-dialog-title"
          open={openSuccess}
        >
          <DialogsTitle
            id="customized-dialog-title"
            onClose={() => {
              handleCloseSuccess();
              navigateTo();
            }}
          >
            {/* Modal title */}
          </DialogsTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={successRegistration} alt="line" />
            </div>
            <div style={{ height: "50px" }}></div>
            <h1 style={{ textAlign: "center" }}>
              Data Sourcer Account has been created successfully
            </h1>
            <p style={{ margin: "0px", textAlign: "center" }}>
              An email as been sent to the newly created account.
            </p>
          </DialogContent>
          <div style={{ height: "50px" }}></div>
          <DialogActions>
            <div
              onClick={navigateTo}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0B5ED7";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0D6EFD";
              }}
              style={{
                height: "45px",
                width: "507px",
                borderRadius: "10px",
                backgroundColor: "#0D6EFD",
                display: "flex",
                alignItems: "center",
                marginRight: "35px",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  margin: "0 auto",
                }}
              >
                Back to dashboard
              </p>
            </div>
          </DialogActions>
          <div style={{ height: "30px" }}></div>
        </Dialogs>
      </div>
    </div>
  );
};

export default Datasourcer;
