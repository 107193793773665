import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({ status }) => {
  let buttonColor;
  switch (status) {
    case "declined":
      buttonColor = "error";
      break;
    case "pending":
      buttonColor = "warning";
      break;
    case "approved":
      buttonColor = "success";
      break;
    default:
      buttonColor = "info";
  }

  const buttonStyle = {
    width: "66px",
    height: "23px",
    backgroundColor: buttonColor,
    fontSize: "10px",
  };

  return (
    <Button style={buttonStyle} variant="contained" color={buttonColor}>
      {status}
    </Button>
  );
};

export default CustomButton;
