import React, { useState, useEffect, useCallback } from "react";
import cicle from "../../images/svgs/line1.svg";
import lineOverall from "../../images/svgs/cirle.svg";
import lineAproved from "../../images/svgs/aprovedlined.svg";
import lineDeclined from "../../images/svgs/lineDeclined.svg";
import linePending from "../../images/svgs/linePendding.svg";
import shortline from "../../images/svgs/shortline.svg";
import iconAproved from "../../images/svgs/iconAproved.svg";
import iconDeclined from "../../images/svgs/iconDeclined.svg";
import iconPending from "../../images/svgs/iconPending.svg";
import datass from "../../images/svgs/datasu.svg";
import arrowRight from "../../images/svgs/chevron_down.svg";
import dataTrans from "../../images/svgs/datatrans.svg";
import ellips1 from "../../images/svgs/Ellipse1.svg";
import ellips2 from "../../images/svgs/Ellipse2.svg";
import dataicon from "../../images/svgs/dateicon.svg";
// import myImage from '../../images/avartar.jpg';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import YearPicker from "./YearPicker";
import "../../styles/DashBoard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import Axios from "axios";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      // text: 'Chart.js Bar Chart',
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return "N " + value + "K";
        },
      },
    },
  },
};

const labels = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "#6F4FE9",
      barPercentage: 0.2,
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "#19C6D1",
      barPercentage: 0.2,
    },
  ],
};

const columns = [
  { id: "name", label: "Name", minWidth: 170, align: "center" },
  { id: "type", label: "Type", minWidth: 100, align: "center" },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
];

const createData = (name, type, date, status) => {
  return { name, type, date, status };
};
const rows = [
  createData("India", "IN", 1324171354, "pending"),
  createData("China", "CN", 1403500365, "approved"),
  createData("Italy", "IT", 60483973, "declined"),
  createData("United States", "US", 327167434, "pending"),
  createData("Canada", "CA", 37602103, "approved"),
  createData("Australia", "AU", 25475400, "pending"),
  createData("Germany", "DE", 83019200, "declined"),
  createData("Ireland", "IE", 4857000, "approved"),
  createData("Mexico", "MX", 126577691, "declined"),
  createData("Japan", "JP", 126317000, "pending"),
  createData("France", "FR", 67022000, "approved"),
  createData("United Kingdom", "GB", 67545757, "declined"),
  createData("Russia", "RU", 146793744, "pending"),
  createData("Nigeria", "NG", 200962417, "approved"),
  createData("Brazil", "BR", 210147125, "declined"),
];

const handleClick = (event) => {
  console.log("Button clicked!", event);
  // Handle button click here
};

const CustomButton = ({ status }) => {
  let buttonColor;
  switch (status) {
    case "declined":
      buttonColor = "primary";
      break;
    case "pending":
      buttonColor = "warning";
      break;
    case "approved":
      buttonColor = "success";
      break;
    default:
      buttonColor = "info";
  }
  return (
    <Button
      style={{ backgroundColor: buttonColor }}
      variant="contained"
      color={buttonColor}
      onClick={handleClick}
    >
      {status}
    </Button>
  );
};

const Dashboard = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [page, setPage] = useState(0);
  const [accepted, setAccepted] = useState("");
  const [declined, setDeclined] = useState("");
  const [pending, setPending] = useState("");
  const [overAll, setOverAll] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [allDataSourcers, setAllDataSourcers] = useState([]);
  const [loadingDataS, setLoadingDataS] = useState(true);
  const [loadingDataT, setLoadingDataT] = useState(true);
  const [loadingRecordedData, setLoadingRecordedData] = useState(true);
  const [allDataTranslators, setAllDataTranslators] = useState([]);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    // Perform any additional logic with the selected year
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getDataSourcers = useCallback(async () => {
    setLoadingDataS(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/users`,
        config
      );

      if (res.data.status === "success") {
        // console.log('All DATA SOURESS', res.data.data.length);
        setAllDataSourcers(res.data.data);
        setAllDataSourcers(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        );
        setLoadingDataS(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataS(false);
    }
  }, [token]);

  const getDataTranslators = useCallback(async () => {
    setLoadingDataT(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/translator/users`,
        config
      );

      if (res.data.status === "success") {
        // console.log('All DATA TRANSLATORES', res.data.data.length);
        setAllDataTranslators(res.data.data);
        setAllDataTranslators(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        );
        setLoadingDataT(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataT(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getDataSourcers();
      getDataTranslators();
    }
  }, [token, getDataSourcers, getDataTranslators]);

  useEffect(() => {
    setLoadingRecordedData(true);
    // let timer;

    // timer = setTimeout(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/admin/sourcerer/recorded-count`,
      {},
      config
    )
      .then((res) => {
        if (res.data.status === "success") {
          setAccepted(res.data.data.accepted);
          setDeclined(res.data.data.declined);
          setPending(res.data.data.pending);
          const overAll =
            res.data.data.accepted +
            res.data.data.declined +
            res.data.data.pending;
          setOverAll(overAll);
          setLoadingRecordedData(false);
        }
      })
      .catch((error) => {
        console.log("Error", error.response.data.message);
        setLoadingRecordedData(false);
      });
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [token]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (loadingDataS) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <p>Loading data....</p>
      </div>
    );
  }
  if (loadingDataT) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <p>Loading data....</p>
      </div>
    );
  }

  if (loadingRecordedData) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <p>Loading data....</p>
      </div>
    );
  }

  console.log(currentUser.token);

  return (
    <div>
      <div className="dash_card_one">
        <div className="dash_title">Data Overview</div>
        <div className="data_overview">
          <div
            style={{
              height: "auto",
              borderRadius: "12px",
              width: "350px",
            }}
          >
            <div
              style={{
                backgroundColor: "#0E61DC",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="p-3">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={lineOverall} alt="line" />
                  </div>
                  <div style={{ marginLeft: "30%" }}>
                    <p style={{ color: "#CEDAFE" }}>Overall</p>
                    <h1 style={{ color: "#FFFFFF" }}>{overAll}</h1>
                  </div>
                </div>
              </div>
              <div>
                <img src={cicle} alt="circle" />
              </div>
            </div>
            <div
              className="p-4"
              style={{
                border: "1px solid #D9E2EC",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={shortline} alt="line" />
                  </div>
                  <div>
                    <p style={{ marginLeft: "0.9rem", color: "#829AB1" }}>
                      Data Sourcer
                    </p>
                  </div>
                </div>
                <div>
                  {allDataSourcers.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>{allDataSourcers.length}</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "10px",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img
                      style={{ marginLeft: "0.5rem" }}
                      src={shortline}
                      alt="line"
                    />
                  </div>
                  <div>
                    <p style={{ marginLeft: "1rem", color: "#829AB1" }}>
                      Data Translator
                    </p>
                  </div>
                </div>
                <div>
                  {allDataTranslators.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>
                      {allDataTranslators.length}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "auto",
              borderRadius: "10px",
              width: "350px",
            }}
          >
            <div
              style={{
                backgroundColor: "#2B7F2F",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="p-3">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={lineAproved} alt="line" />
                  </div>
                  <div style={{ marginLeft: "30%" }}>
                    <p style={{ color: "#ABFEC1" }}>Approved</p>
                    <h1 style={{ color: "#FFFFFF" }}>{accepted}</h1>
                  </div>
                </div>
              </div>
              <div>
                <img src={iconAproved} alt="circle" />
              </div>
            </div>
            <div
              className="p-4"
              style={{
                border: "1px solid #D9E2EC",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={shortline} alt="line" />
                  </div>
                  <div>
                    <p style={{ marginLeft: "0.9rem", color: "#829AB1" }}>
                      Data Sourcer
                    </p>
                  </div>
                </div>
                <div>
                  {allDataSourcers.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>{allDataSourcers.length}</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "10px",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img
                      style={{ marginLeft: "0.5rem" }}
                      src={shortline}
                      alt="line"
                    />
                  </div>
                  <div>
                    <p style={{ marginLeft: "1rem", color: "#829AB1" }}>
                      Data Translator
                    </p>
                  </div>
                </div>
                <div>
                  {allDataTranslators.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>
                      {allDataTranslators.length}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "auto",
              borderRadius: "10px",
              width: "350px",
            }}
          >
            <div
              style={{
                backgroundColor: "#DD0A0A",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="p-3">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={lineDeclined} alt="line" />
                  </div>
                  <div style={{ marginLeft: "30%" }}>
                    <p style={{ color: "#FFBDBD" }}>Declined</p>
                    <h1 style={{ color: "#FFFFFF" }}>{declined}</h1>
                  </div>
                </div>
              </div>
              <div>
                <img src={iconDeclined} alt="circle" />
              </div>
            </div>
            <div
              className="p-4"
              style={{
                border: "1px solid #D9E2EC",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={shortline} alt="line" />
                  </div>
                  <div>
                    <p style={{ marginLeft: "0.9rem", color: "#829AB1" }}>
                      Data Sourcer
                    </p>
                  </div>
                </div>
                <div>
                  {allDataSourcers.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>{allDataSourcers.length}</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "10px",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img
                      style={{ marginLeft: "0.5rem" }}
                      src={shortline}
                      alt="line"
                    />
                  </div>
                  <div>
                    <p style={{ marginLeft: "1rem", color: "#829AB1" }}>
                      Data Translator
                    </p>
                  </div>
                </div>
                <div>
                  {allDataTranslators.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>
                      {allDataTranslators.length}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "auto",
              borderRadius: "10px",
              width: "350px",
            }}
          >
            <div
              style={{
                backgroundColor: "#FD9B16",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="p-3">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={linePending} alt="line" />
                  </div>
                  <div style={{ marginLeft: "30%" }}>
                    <p style={{ color: "#FFEAC6" }}>Pending</p>
                    <h1 style={{ color: "#FFFFFF" }}>{pending}</h1>
                  </div>
                </div>
              </div>
              <div>
                <img src={iconPending} alt="circle" />
              </div>
            </div>
            <div
              className="p-4"
              style={{
                border: "1px solid #D9E2EC",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img src={shortline} alt="line" />
                  </div>
                  <div>
                    <p style={{ marginLeft: "0.9rem", color: "#829AB1" }}>
                      Data Sourcer
                    </p>
                  </div>
                </div>
                <div>
                  {allDataSourcers.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>{allDataSourcers.length}</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "10px",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div>
                    <img
                      style={{ marginLeft: "0.5rem" }}
                      src={shortline}
                      alt="line"
                    />
                  </div>
                  <div>
                    <p style={{ marginLeft: "1rem", color: "#829AB1" }}>
                      Data Translator
                    </p>
                  </div>
                </div>
                <div>
                  {allDataTranslators.length === 0 ? (
                    <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                  ) : (
                    <p style={{ color: "#486581" }}>
                      {allDataTranslators.length}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "20px" }}></div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          columnGap: "20px",
          paddingRight: "20px",
        }}
      >
        <div className="col-lg-6">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "93px",
                width: "300px",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                border: "1px solid #D9E2EC",
                justifyContent: "space-between",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div>
                <img src={datass} alt="datasourcer" />
              </div>
              <div>
                <p style={{ color: "#486581", margin: "0px" }}>Data Sourcer</p>
                {allDataSourcers.length === 0 ? (
                  <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                ) : (
                  <p
                    style={{
                      color: "#102A43",
                      margin: "0px",
                      fontSize: "32px",
                    }}
                  >
                    {allDataSourcers.length}
                  </p>
                )}
              </div>
              <div>
                <img src={arrowRight} alt="line" />
              </div>
            </div>
            <div style={{ width: "20px" }}></div>
            <div
              style={{
                height: "93px",
                width: "300px",
                border: "1px solid #D9E2EC",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div>
                <img src={dataTrans} alt="datatrans" />
              </div>
              <div>
                <p style={{ color: "#486581", margin: "0px" }}>
                  Data Translator
                </p>
                {allDataTranslators.length === 0 ? (
                  <p style={{ textAlign: "center", fontWeight: "600" }}>0</p>
                ) : (
                  <p
                    style={{
                      color: "#102A43",
                      margin: "0px",
                      fontSize: "32px",
                    }}
                  >
                    {allDataTranslators.length}
                  </p>
                )}
              </div>
              <div>
                <img src={arrowRight} alt="line" />
              </div>
            </div>
          </div>

          <div style={{ height: "auto", marginTop: "10px" }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <p
                          style={{
                            color: "#000000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Recently Submitted Data
                        </p>
                      </TableCell>
                      <TableCell colSpan={3}>{/* Details */}</TableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ top: 57, minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format &&
                                  typeof value === "number" ? (
                                    column.format(value)
                                  ) : column.id === "status" ? (
                                    <CustomButton status={value} />
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        <div
          className="col-lg-6"
          style={{
            height: "595px",
            width: "50%",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <p
              style={{
                color: "#000000",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Expenditure Analysis
            </p>
            <p style={{ color: "#121212" }}>
              <img src={ellips1} alt="ellips" /> Data Sourcers
            </p>
            <p style={{ color: "#121212" }}>
              <img src={ellips2} alt="ellips" /> Data Translators
            </p>
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#F3F9FF",
                width: "76px",
                height: "31",
                borderRadius: "10px",
                justifyContent: "center",
              }}
            >
              <img src={dataicon} alt="line" />
              <YearPicker
                selectedYear={selectedYear}
                onYearSelect={handleYearSelect}
              />
            </div>
          </div>
          <p></p>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginLeft: "6%",
            }}
          >
            <div
              style={{
                height: "110px",
                width: "205px",
                borderRadius: "10px",
                backgroundColor: "#9FB3C8",
                border: "1px solid #D9E2EC",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ margin: "0px", color: "#F3F9FF" }}>Total Exp.</p>
              <p
                style={{
                  margin: "0px",
                  color: "#F3F9FF",
                  fontWeight: "600",
                  fontSize: "24px",
                }}
              >
                ₦723,430
              </p>
            </div>

            <div
              style={{
                height: "110px",
                width: "205px",
                borderRadius: "10px",
                backgroundColor: "white",
                border: "1px solid #D9E2EC",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ margin: "0px", color: "#486581" }}>Data Sourcer</p>
              <p
                style={{
                  margin: "0px",
                  color: "#102A43",
                  fontWeight: "600",
                  fontSize: "24px",
                }}
              >
                ₦723,430
              </p>
            </div>
            <div
              style={{
                height: "110px",
                width: "205px",
                borderRadius: "10px",
                backgroundColor: "white",
                border: "1px solid #D9E2EC",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ margin: "0px", color: "#486581" }}>Data Translator</p>
              <p
                style={{
                  margin: "0px",
                  color: "#102A43",
                  fontWeight: "600",
                  fontSize: "24px",
                }}
              >
                ₦723,430
              </p>
            </div>
          </div>

          <div>
            <Bar options={options} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
