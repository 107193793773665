import React, { useEffect, useState } from "react";
import { NoUserUrl } from "../../components/noUserImg";
import iconOverall from "../../images/svgs/line1.svg";
import line from "../../images/svgs/line.svg";
import iconAproved from "../../images/svgs/iconAproved.svg";
import iconDeclined from "../../images/svgs/iconDeclined.svg";
import iconPending from "../../images/svgs/iconPending.svg";
import successIcon from "../../images/dash/success.svg";
import failureIcon from "../../images/svgs/failure.svg";
import fal from "../../images/svgs/fal.svg";
import Accordion from "react-bootstrap/Accordion";
import CustomButton from "../common/CustomButton";
import { useParams } from "react-router-dom";
import axios from "axios";
import Waveform from "../sourcrers/Waveform";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export const UserRecorder = () => {
  const { id } = useParams();
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [loadingUser, setLoadingUser] = useState(true);
  const [userData, setUserData] = useState([]);
  const [recording, setRecording] = useState([]);
  const [recordId, setRecordId] = useState();
  const [showRecording, setShowRecording] = useState(false);
  const [allLanguage, setAllLanguage] = useState([]);
  const [pendingRecord, setPendingRecord] = useState(null);
  const [declinedRecord, setDeclinedRecord] = useState(null);
  const [acceptedRecord, setAcceptedRecord] = useState(null);
  const [allRecord, setAllRecord] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData({
      // first_name: userData.first_name,
      language_id: userData.language_id,
      // last_name: userData.last_name,
      // phone_number: userData.phone_number,
    });
    setShow(true);
  };
  const [show, setShow] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [saving, setSaving] = useState(false);
  const [buttonStatus, setButtonStatus] = useState("");
  const [message, setMessage] = useState(null);

  const handleSelectedRecording = (data) => {
    // console.log(data);
    setRecordId(data.id);
    setShowRecording(true);
    setRecording(data);
    setButtonStatus(data.status);
  };

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/users/${id}`,
          config
        );
        if (response.data.status === "success") setLoadingUser(false);
        setUserData(response.data.data);
        console.log(id);
        if (id !== "") recordedCount(id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/admin/language`, config)
        .then((res) => setAllLanguage(res.data.data))
        .catch((error) => console.error("Error fetching languages:", error));
    };
    fetchData();
  }, [id, token]);

  const recordedCount = async (userId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      recorder_id: userId,
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/recorded-count`,
    //     data,
    //     config
    //   )
    //   .then((response) => {
    //     console.log(response.data);
    //     setPendingRecord(response.data.data.pending);
    //     setDeclinedRecord(response.data.data.declined);
    //     setAcceptedRecord(response.data.data.accepted);
    //     setAllRecord(
    //       response.data.data.pending +
    //         response.data.data.declined +
    //         response.data.data.accepted
    //     );
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/recorded-count`,
        data,
        config
      );

      if (response.data.status === "success" && userData) {
        setPendingRecord(response.data.data.pending);
        setDeclinedRecord(response.data.data.declined);
        setAcceptedRecord(response.data.data.accepted);
        setAllRecord(
          response.data.data.pending +
            response.data.data.declined +
            response.data.data.accepted
        );
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = (id) => {
    statusChangeSubmit(id, "accepted");
  };

  const handleDecline = (id) => {
    statusChangeSubmit(id, "declined");
  };

  const statusChangeSubmit = async (id, status) => {
    setSaving(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      data_id: id,
      status: status,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/recorded-status`,
        data,
        config
      );
      if (response.data.status === "success") {
        setSaving(false);
        setMessage({
          status: "success",
          message: response.data.message,
        });
        setButtonStatus(data.status);
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
    }
  };

  const [formData, setFormData] = useState({
    // first_name: "",
    language_id: "",
    // last_name: "",
    // phone_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/users/${id}`,
        formData,
        config
      );

      if (response.data.status === "success") {
        setSaving(false);
        setMessage({
          status: "success",
          message: "Recorder edited successfully",
        });
        setFormData({
          // first_name: "",
          language_id: formData.language_id,
          // last_name: "",
          // phone_number: "",
        });
        //fetchData();
        setShow(false);
      } else {
        setMessage({
          status: "error",
          message: `Editing recorder failed ${response.data.message}`,
        });
      }
    } catch (error) {
      setSaving(false);
      setMessage({
        status: "error",
        message: `Error editing recorder! ${error.message}`,
      });
    }
  };

  if (loadingUser) {
    return <p className="text-center fw-bold fs-5">Loading...</p>;
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 user-recorder-left">
            <div className="recorder-details-left">
              <div>
                <img
                  src={
                    userData.profile_image === ""
                      ? userData.profile_image
                      : NoUserUrl
                  }
                  alt="profile"
                  className="profile-image"
                />
              </div>
              <div className="profile-content">
                <div className="profile-name">
                  {userData.first_name} {userData.last_name}
                </div>
                <div className="profile-email">{userData.email}</div>
                <div className="profile-phone">
                  Phone No.:&nbsp;
                  <span className="profile-no">{userData.phone_number}</span>
                </div>
                <div className="profile-edit" onClick={() => handleShow()}>
                  Edit Profile
                </div>
              </div>
            </div>

            <div className="details">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Payment</Accordion.Header>
                  <Accordion.Body>
                    <div className="det-list">
                      <div className="det-list-left">Amount gotten: </div>
                      <div className="det-list-right">₦0,000</div>
                    </div>
                    <div className="det-list">
                      <div className="det-list-left">Amount withdrawn: </div>
                      <div className="det-list-right">₦0,000</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Account Info</Accordion.Header>
                  <Accordion.Body>
                    {userData && userData.account ? (
                      <>
                        <div className="det-list">
                          <div className="det-list-left">Full name: </div>
                          <div className="det-list-right">
                            {userData.account.fullname}
                          </div>
                        </div>
                        <div className="det-list">
                          <div className="det-list-left">Accnt No.: </div>
                          <div className="det-list-right">
                            {userData.account.account_number}
                          </div>
                        </div>
                        <div className="det-list">
                          <div className="det-list-left">Bank:</div>
                          <div className="det-list-right">
                            {userData.account.bank.name}
                          </div>
                        </div>
                        <div className="det-list">
                          <div className="det-list-left">Accnt type: </div>
                          <div className="det-list-right">
                            {userData.account.account_type}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>User yet to set up account</p>
                      </>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Sessions Done</Accordion.Header>
                  <Accordion.Body>
                    {userData.recorded_data.map((recorded, index) => {
                      return (
                        <div
                          className={`det-session ${
                            recordId === recorded.id ? "det-session-active" : ""
                          }`}
                          key={index}
                          onClick={() => handleSelectedRecording(recorded)}
                        >
                          Recording {recorded.id}
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-6">
            {/* Small cols */}
            <div className="row small-cols-group">
              <div className="col-3 small-cols blue">
                <img src={iconOverall} alt="line" className="summary-image" />
                <div className="small-cols-inner">
                  <img src={line} className="opacity-5" alt="line" />
                  <div className="ms-1 mt-2">
                    <p className="summary-title">Overall</p>
                    <p className="summary-total">{allRecord ? allRecord : 0}</p>
                  </div>
                </div>
              </div>

              <div className="col-3 small-cols green">
                <img src={iconAproved} alt="line" className="summary-image" />
                <div className="small-cols-inner">
                  <img src={line} className="opacity-5" alt="line" />
                  <div className="ms-1 mt-2">
                    <p className="summary-title">Approved</p>
                    <p className="summary-total">
                      {acceptedRecord ? acceptedRecord : 0}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-3 small-cols red">
                <img src={iconDeclined} alt="line" className="summary-image" />
                <div className="small-cols-inner">
                  <img src={line} className="opacity-5" alt="line" />
                  <div className="ms-1 mt-2">
                    <p className="summary-title">Declined</p>
                    <p className="summary-total">
                      {declinedRecord ? declinedRecord : 0}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-3 small-cols orange">
                <img src={iconPending} alt="line" className="summary-image" />
                <div className="small-cols-inner">
                  <img src={line} className="opacity-5" alt="line" />
                  <div className="ms-1 mt-2">
                    <p className="summary-title">Pending</p>
                    <p className="summary-total">
                      {pendingRecord ? pendingRecord : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Show Session Detail */}
            {showRecording && (
              <div className="row user-session-right">
                <div className="session-header">
                  Recording - {recording.id}
                  <CustomButton status={buttonStatus} />
                </div>
                <div className="user-recording">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="det-list-left">
                          Recording - {recording.id}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="record-textarea">
                          {recording.recorder_data.text}
                        </div>

                        <Waveform records={recording.recording} />

                        <div className="spacer justify-content-end">
                          <button
                            className="btn btn-primary"
                            onClick={() => handleApprove(recording.id)}
                          >
                            Approve
                          </button>
                          <button
                            className="btn text-red"
                            onClick={() => handleDecline(recording.id)}
                          >
                            Decline
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal to Add New Recorder */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Recorder</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "50px" }}>
          <form onSubmit={handleSubmit}>
            {/* <div className="form-group mb-3">
              <label htmlFor="first_name" className="input-text">
                First Name
              </label>
              <input
                type="text"
                className="form-control input-field mt-2"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First name"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="last_name" className="input-text">
                Last Name
              </label>
              <input
                type="text"
                className="form-control input-field mt-2"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last name"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="phone_number" className="input-text">
                Phone number
              </label>
              <input
                type="text"
                id="phone_number"
                className="form-control input-field"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Phonenumber"
                required
              />
            </div> */}

            <div className="form-group mb-3">
              <label htmlFor="language" className="input-text">
                Language
              </label>
              <select
                className="form-select input-field"
                id="language"
                name="language_id"
                value={formData.language_id}
                onChange={handleChange}
                required
              >
                <option>Select Language</option>
                {allLanguage.map((language, index) => (
                  <React.Fragment key={index}>
                    <option value={language.id}>{language.name}</option>
                  </React.Fragment>
                ))}
              </select>
            </div>

            <button className="input-submit mt-3">Edit</button>
          </form>
        </Modal.Body>
      </Modal>
      {/* Modal to Add New Recorder */}

      {/* Display status messages */}
      {message && (
        <div className="message-overlay">
          <div className="message">
            <div>
              <img
                src={message.status === "success" ? successIcon : failureIcon}
                alt="Success"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="message-text mt-3">{message.message}</div>
            <div>
              <button
                type="button"
                className="btn btn-warning mt-3"
                onClick={() => {
                  setMessage(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Display status messages */}

      {/* Saving */}
      {saving && (
        <div className="message-overlay">
          <div className="message">
            <img src={fal} alt="loading..." />
          </div>
        </div>
      )}
      {/* Saving */}
    </>
  );
};
