import React from "react";
import { useState } from "react";
import on from "../../images/dash/on.svg";
import off from "../../images/dash/off.svg";

export const Notify = () => {
  const [isPushAccepted, setIsPushAccepted] = useState(false);
  const [isEmailAccepted, setIsEmailAccepted] = useState(false);

  const pushToggle = () => {
    setIsPushAccepted((prevState) => !prevState);
  };

  const emailToggle = () => {
    setIsEmailAccepted((prevState) => !prevState);
  };
  return (
    <>
      <div className="span-notify">
        <div className="notify_text">
          Get push notification to find out whats going on when you are online
        </div>
        <div className="theja">
          <img
            src={isPushAccepted ? off : on}
            alt="on"
            onClick={() => pushToggle()}
          />
        </div>
      </div>

      <div className="span-notify">
        <div className="notify_text">
          Get email notification to find out whats going on when you are offline
        </div>
        <div className="theja">
          <img
            src={isEmailAccepted ? off : on}
            alt="on"
            onClick={() => emailToggle()}
          />
        </div>
      </div>
    </>
  );
};
