import React from "react";

export const Help = () => {
  const ada = false;
  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          alignItems: "flex-start",
        }}
      >
        <table className="table style--one help--font">
          {ada === true ? (
            <p style={{ textAlign: "center", fontWeight: "600" }}>
              No registered translator available
            </p>
          ) : (
            <tbody>
              <tr style={{ background: "red" }}>
                <th>Name</th>
                <th>Type</th>
                <th>Message</th>
                <th>Date</th>
                <th className="text-center">Status</th>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <td>Chidimma Ode</td>
                <td>Grp ID - 123451VDR</td>
                <td>
                  I recorded since two weeks ago and I'm yet to be paid for it.
                  I look for to receiving my payment soon. Thank you.
                </td>
                <td>Jul 29, 2023</td>
                <td>
                  <button className="btn-status">Pending</button>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
