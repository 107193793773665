import React,{useEffect} from 'react'

const DataSourcers = () => {

  useEffect(() => { document.body.style.backgroundColor = '#F2F8FD' }, [])
    
  return (
        <div>
          <h1>DataSourcers</h1>
</div>
    )
}

export default DataSourcers;