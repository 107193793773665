export const NoPageFound = () => {
  return (
    <div className="container d-flex justify-center align-item-center flex-column h-75">
      {" "}
      <h1
        className="text-center "
        style={{ fontSize: "12rem", paddingTop: "4rem" }}
      >
        404
      </h1>
      <h1 className="text-center">
        The Page you are looking for cannot be found!
      </h1>{" "}
      <a
        href="/"
        className="btn btn-warning btn-md m-auto mt-5"
        style={{ width: "240px" }}
      >
        Go Home
      </a>
    </div>
  );
};

export default NoPageFound;
