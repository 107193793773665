import React, { Suspense, useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoadingLargeIcon } from "./icons";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/auth/ForgotPassword";
import NoPageFound from "./pages/NopageFound/NotFoundPage";
import Dashboard from "./pages/Dashboard/DashBoard";
import Admin from "./pages/Admin/Admin";
import Layout from "./pages/Layout";
import Datasourcer from "./pages/sourcrers/Datasourcer";
import Translator from "./pages/translators/Translator";
import { Settings } from "./pages/Settings/Settings";
import Datas from "./pages/Datas/Datas";
import Recorder from "./pages/recorders/Recorder";
import { UserRecorder } from "./pages/recorders/UserRecorder";
import { useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./components/Login";

import { logout } from "./slices/auth";

import EventBus from "./common/EventBus";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      //setShowModeratorBoard(currentUser.admin.roles.includes("ROLE_MODERATOR"));
      //setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      console.log("User logged in");
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <LoadingLargeIcon />
        </div>
      }
    >
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/datasourcer" element={<Datasourcer />} />
            <Route path="/translators" element={<Translator />} />
            <Route path="/recorders" element={<Recorder />} />
            <Route path="/recorder/:id/user" element={<UserRecorder />} />
            <Route path="/data" element={<Datas />} />
            <Route path="/settings" element={<Settings />} />\
          </Route>
          <Route path="/*" element={<NoPageFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
