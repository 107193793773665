import React, { useCallback, useEffect, useState } from "react";
import threeicon from "../../images/svgs/threeicon.svg";
import plus from "../../images/svgs/pluss.svg";
import { NoUserUrl } from "../../components/noUserImg";
import iconOverall from "../../images/svgs/line1.svg";
import line from "../../images/svgs/line.svg";
import iconAproved from "../../images/svgs/iconAproved.svg";
import iconDeclined from "../../images/svgs/iconDeclined.svg";
import iconPending from "../../images/svgs/iconPending.svg";
import CustomButton from "../common/CustomButton";
import { Link } from "react-router-dom";
import axios from "axios";
import fal from "../../images/svgs/fal.svg";
import { Modal } from "react-bootstrap";
import successIcon from "../../images/dash/success.svg";
import failureIcon from "../../images/svgs/failure.svg";
import { useSelector } from "react-redux";

const Recorder = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = currentUser.token;
  const [allLanguage, setAllLanguage] = useState([]);
  const [allDataRecorders, setAllDataRecorders] = useState([]);
  const [pendingRecord, setPendingRecord] = useState(null);
  const [declinedRecord, setDeclinedRecord] = useState(null);
  const [acceptedRecord, setAcceptedRecord] = useState(null);
  const [allRecord, setAllRecord] = useState(null);
  const [loadingRecorders, setLoadingRecorders] = useState(true);

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getAllData = useCallback(async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/recorder/users`, config)
      .then((res) =>
        setAllDataRecorders(
          res.data.data.sort((a, b) => b.created_at.localeCompare(a.created_at))
        )
      )
      .catch((error) => console.error("Error fetching roles:", error));

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/language`, config)
      .then((res) => setAllLanguage(res.data.data))
      .catch((error) => console.error("Error fetching languages:", error));

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/recorded-count`,
        {},
        config
      )
      .then((response) => {
        setPendingRecord(response.data.data.pending);
        setDeclinedRecord(response.data.data.declined);
        setAcceptedRecord(response.data.data.accepted);
        setAllRecord(
          response.data.data.pending +
            response.data.data.declined +
            response.data.data.accepted
        );
      })
      .catch((error) => console.error("Error fetching counts:", error));

    setLoadingRecorders(false);
  }, [token]);

  useEffect(() => {
    if (token) {
      getAllData();
    }
  }, [token, getAllData]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    language_id: "",
    last_name: "",
    phone_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    // console.log(formData);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/recorder/register`,
        formData,
        config
      );

      if (response.data.status === "success") {
        setSaving(false);
        setMessage({
          status: "success",
          message: "Recorder created successfully",
        });
        setFormData({
          email: "",
          password: "",
          first_name: "",
          language_id: "",
          last_name: "",
          phone_number: "",
        });
        getAllData();
        setShow(false);
      } else {
        setMessage({
          status: "error",
          message: `Recorder creation failed ${response.data.message}`,
        });
      }
    } catch (error) {
      setSaving(false);
      setMessage({
        status: "error",
        message: `Error creating recorder! ${error.message}`,
      });
    }
  };

  // console.log(recordedCount);

  if (loadingRecorders) {
    return (
      <p className="text-center fw-bold fs-5">
        Loading&nbsp;
        <img src={fal} alt="loading..." />
      </p>
    );
  }

  return (
    <>
      <div className="recorder-all">
        <div className="card col-6 border-null">
          <div className="dta-cover">
            <div>
              <h4 className="header-title">Data Recorder</h4>
            </div>
            <div onClick={handleShow} className="opne-plus">
              <div>
                <img src={plus} alt="plussign" />
              </div>
              <div>
                <h4 className="add-trans ">Add New Recorder</h4>
              </div>
            </div>
          </div>

          {/* List all recorders */}
          <div className="card-body p-0 bg-none border-null scroll-true">
            {allDataRecorders.length === 0 ? (
              <p className="no-data">No registered translator available</p>
            ) : (
              <>
                {allDataRecorders.map((recorder, index) => {
                  return (
                    <div className="dta_list" key={index}>
                      <div className="dta-profile">
                        <img
                          src={
                            recorder.profile_image
                              ? recorder.profile_image
                              : NoUserUrl
                          }
                          className="dta-image"
                          alt="profile"
                        />
                        <div className="dta-user">
                          <Link
                            to={`/recorder/${recorder.id}/user`}
                            className="text-decoration-none"
                          >
                            {recorder.first_name} {recorder.last_name}
                          </Link>
                        </div>
                      </div>
                      <div className="dta-email">{recorder.email}</div>
                      <div className="">
                        <Link
                          to={`/recorder/${recorder.id}/user`}
                          className="icon-button"
                        >
                          <img src={threeicon} alt="iconns" />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="col-6 ">
          {/* Small cols */}
          <div className="row small-cols-group">
            <div className="col-3 small-cols blue">
              <img src={iconOverall} alt="line" className="summary-image" />
              <div className="small-cols-inner">
                <img src={line} className="opacity-5" alt="line" />
                <div className="ms-1 mt-2">
                  <p className="summary-title">Overall</p>
                  <p className="summary-total">{allRecord ? allRecord : "0"}</p>
                </div>
              </div>
            </div>

            <div className="col-3 small-cols green">
              <img src={iconAproved} alt="line" className="summary-image" />
              <div className="small-cols-inner">
                <img src={line} className="opacity-5" alt="line" />
                <div className="ms-1 mt-2">
                  <p className="summary-title">Approved</p>
                  <p className="summary-total">
                    {acceptedRecord ? acceptedRecord : "0"}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-3 small-cols red">
              <img src={iconDeclined} alt="line" className="summary-image" />
              <div className="small-cols-inner">
                <img src={line} className="opacity-5" alt="line" />
                <div className="ms-1 mt-2">
                  <p className="summary-title">Declined</p>
                  <p className="summary-total">
                    {declinedRecord ? declinedRecord : "0"}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-3 small-cols orange">
              <img src={iconPending} alt="line" className="summary-image" />
              <div className="small-cols-inner">
                <img src={line} className="opacity-5" alt="line" />
                <div className="ms-1 mt-2">
                  <p className="summary-title">Pending</p>
                  <p className="summary-total">
                    {pendingRecord ? pendingRecord : "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Recently submitted */}
          <div className="small-cols-group mt-4">
            <div className="recent-title">Recently Submitted Data</div>

            {allDataRecorders.length === 0 ? (
              <p className="no-data">No recently submitted data available</p>
            ) : (
              <div>
                <div className="recent-status">
                  <p className="recent-tabs">Name</p>
                  <p className="recent-tabs ">Type</p>
                  <p className="recent-tabs">Date</p>
                  <p className="recent-tabs">Status</p>
                </div>

                <div style={{ overflow: "auto", maxHeight: "600px" }}>
                  {allDataRecorders.map((datasource, index) => {
                    return (
                      <div key={index}>
                        <div className="recent-cover">
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <img
                                className="dta-image"
                                src={
                                  datasource.profile_image
                                    ? datasource.profile_image
                                    : NoUserUrl
                                }
                                alt="profile-images"
                              />
                            </div>
                            <div style={{ width: "10px" }}></div>
                            <div>
                              <p className="recent-tabs-tick">
                                {datasource.first_name} {datasource.last_name}
                              </p>
                              <p className="recent-tabs">Data datasource</p>
                            </div>
                          </div>
                          <div>
                            <p className="recent-tabs-tick text-center">10</p>
                            <p className="recent-tabs">10 out of 100</p>
                          </div>
                          <div>
                            <p className="recent-tabs-tick">Jul 29, 2023</p>
                            <p className="recent-tabs">10:23am</p>
                          </div>
                          <div>
                            <CustomButton status="pending" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal to Add New Recorder */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Recorder</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "50px" }}>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="first_name" className="input-text">
                First Name
              </label>
              <input
                type="text"
                className="form-control input-field mt-2"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First name"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="last_name" className="input-text">
                Last Name
              </label>
              <input
                type="text"
                className="form-control input-field mt-2"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last name"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="email" className="input-text">
                Email Address
              </label>
              <input
                type="email"
                className="form-control input-field mt-2"
                name="email"
                value={formData.email}
                onChange={handleChange}
                id="email"
                placeholder="Email Address"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="phone_number" className="input-text">
                Phone number
              </label>
              <input
                type="text"
                id="phone_number"
                className="form-control input-field"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Phonenumber"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="language" className="input-text">
                Language
              </label>
              <select
                className="form-select input-field"
                id="language"
                name="language_id"
                value={formData.language_id}
                onChange={handleChange}
                required
              >
                <option>Select Language</option>
                {allLanguage.map((language, index) => (
                  <React.Fragment key={index}>
                    <option value={language.id}>{language.name}</option>
                  </React.Fragment>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="password" className="input-text">
                Password
              </label>
              <input
                type="password"
                className="form-control input-field mt-2"
                name="password"
                value={formData.password}
                onChange={handleChange}
                id="password"
                placeholder="*********"
                required
              />
            </div>

            <button className="input-submit mt-3">Add</button>
          </form>
        </Modal.Body>
      </Modal>
      {/* Modal to Add New Recorder */}

      {/* Display status messages */}
      {message && (
        <div className="message-overlay">
          <div className="message">
            <div>
              <img
                src={message.status === "success" ? successIcon : failureIcon}
                alt="Success"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="message-text mt-3">{message.message}</div>
            <div>
              <button
                type="button"
                className="btn btn-warning mt-3"
                onClick={() => {
                  setMessage(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Display status messages */}

      {/* Saving */}
      {saving && (
        <div className="message-overlay">
          <div className="message">
            <img src={fal} alt="loading..." />
          </div>
        </div>
      )}
      {/* Saving */}
    </>
  );
};

export default Recorder;
