import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const text = `Welcome to Omeif Data!

Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.

Definitions and key terms
By using Omeif Data's  services, you consent to the practices described in this Privacy Policy.
1. Information We Collect :
We collect various types of information to provide and improve our services: 
1.1. Personal Information :
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
1.2. Device Information :
We collect information about the device and software you use to access our services, including device type, operating `;

export const Privacy = () => {
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (data) => {
    setEditorData(data);
  };

  return (
    <div>
      <h5>Privacy and Security</h5>
      <CKEditorComponent onEditorChange={handleEditorChange} />
      <div>
        <div dangerouslySetInnerHTML={{ __html: editorData }} />
      </div>
      <div className="button-cover">
        <button className="button-save">Save</button>
      </div>
    </div>
  );
};

const CKEditorComponent = ({ onEditorChange }) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={text}
        onChange={(event, editor) => {
          const data = editor.getData();
          onEditorChange(data);
        }}
      />
    </div>
  );
};

export default CKEditorComponent;
