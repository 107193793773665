import React, { useEffect } from "react";

const AllDatas = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F2F8FD";
  }, []);
  const ada = false;

  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          alignItems: "flex-start",
        }}
      >
        <table className="table style--one">
          {ada === true ? (
            <p style={{ textAlign: "center", fontWeight: "600" }}>
              No registered translator available
            </p>
          ) : (
            <tbody>
              <tr style={{ background: "red" }}>
                <th>Name</th>
                <th>DataType</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <td>Chidimma Ode</td>
                <td>Data Source</td>
                <td>10/02/2023</td>
                <td>String</td>
                <td>Done</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default AllDatas;
